import React, { useEffect, useState } from "react";
import loginStyle from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { addUserOtpSignUp, userLogin, userVerifyOtp, } from "../../Services/AllApi";
import toast from "react-hot-toast";


export default function UserLogin() {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('')
  const [id, setId] = useState('')
  const [formUserData, setFormUserData] = useState({
    mobile: "",
    countryCode: "+91",
  });

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormUserData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  };

  const handleUserSignIn = async (e) => {
    e.preventDefault();
    if (formUserData?.countryCode == '+91') {
      if (formUserData?.mobile.length < 10) {
        toast.error("Please enter 10 digits number.");
        return;
      }
    }
    const fullMobileNumberUser = `${formUserData.countryCode}${formUserData.mobile}`;

    const payloadUser = { mobile: fullMobileNumberUser };
    const payloadUserVerify = {
      mobile: fullMobileNumberUser,
      otp: "0000",
    };
    setLoading(true);

    try {
      await userLogin(payloadUser);
      if (payloadUser.mobile.includes("+971")) {
        const resverify = await userVerifyOtp(payloadUserVerify);
        if (resverify?.data?.userData?.firstname) {
          const userToken = {
            token: resverify?.data?.token,
          };
          localStorage.setItem("UserDetail", JSON.stringify(userToken));
          if (type == null) {
            navigate("/view-profile");
            // window.location.href = "https://eyemastr.com/";
          } else {
            navigate(`/${type}/${id}`);
          }
        } else {
          navigate("/user-profile");
        }
      } else {
        toast.success("OTP has been sent on your Mobile Number");
        setFormUserData("");
        navigate("/verifyOTP", { state: { payloadUser } });
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.message || 'Some error occupide please check.'
      toast.error(error)
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    const type1 = urlParams.get('type');
    const id1 = urlParams.get('id');

    if (type1 === null) {
      setType(null)
    } else {
      setType(type1)
    }

    if (id1 === null) {
      setId(null)
    } else {
      setId(type)
    }
  }, [])

  return (
    <div className={`registrationBanner p-md-5 p-3  `}>
      <div className={`container ${loginStyle.login_container}`}>
        <div className="mt-5">
          <div className={`row`}>
            <div className="col-lg-6 col-md-10 col-12 px-md-3 px-2 py-4 rounded bg-white ">
              <div className="heading me-3">
                Log in to access your account
              </div>

              <form onSubmit={handleUserSignIn}>
                <div className="col-12 form-group mt-3">
                  <label className="">
                    Mobile Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group mt-1">
                    <select
                      name="countryCode"
                      style={{ maxWidth: "150px" }}
                      value={formUserData.countryCode}
                      className="form-select pointer"
                      onChange={handleChangeUser}
                    >
                      <option value="+91" className="pointer">
                        IN(+91)
                      </option>
                      <option value="+971" className="pointer">
                        UAE(+971)
                      </option>
                    </select>
                    <input
                      type="number"
                      placeholder="Enter your Mobile Number"
                      required
                      name="mobile"
                      value={formUserData.mobile}
                      className="form-control"
                      onChange={handleChangeUser}
                      onInput={(e) => {
                        if (formUserData?.countryCode === '+91') {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                      }}

                    />
                  </div>
                </div>
                <div className=" me-3 mt-3">
                  Don't have any account? <Link to="/signup" style={{ textDecoration: 'none' }}>Create New</Link>
                </div>
                <div className="col-12 mt-4">
                  <button
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
