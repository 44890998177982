import React, { useState, useEffect } from 'react'
import { deleteImg, professionalView, profileData, uploadImg } from '../../Services/AllApi';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { MdOutlineFileUpload } from "react-icons/md";
import MapComponent from '../../Components/MapComponents/MapComponent';
import { Modal, ModalBody } from 'reactstrap';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import Multiselect from 'multiselect-react-dropdown';
import SearchBox from '../../Components/MapComponents/SearchBox';
import { IoMdClose } from "react-icons/io";
import Loader from '../../Components/Loader/Loader';
import { FaRegEdit } from "react-icons/fa";
import ViewFile from '../../Components/DocumentCard/ViewFile';


const ProfileView = () => {
    const userToken = JSON.parse(localStorage.getItem("UserDetail"));
    const apiKey = 'AIzaSyCloFlxsLYW2fYowdLLwu7K7XaJz0mMrxQ'
    const navigate = useNavigate();
    const [edit, setEdit] = useState(true)
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [img, setImg] = useState("");
    const [placeholder, setPlaceholder] = useState("Select");
    const [addressstr, setAddressStr] = useState('')
    const [speciality, setSpeciality] = useState([])
    const [specialityBack, setSpecialityBack] = useState([])
    const [viewFileModal, setViewFileModal] = useState(false)
    const [fileForView, setFileForView] = useState({})
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [doc, setDoc] = useState([])
    const [innerData, setinnerData] = useState({})
    const [deleteFile, setDeleteFile] = useState([])
    const [selectedPosition, setSelectedPosition] = useState({
        lat: 40.7128,
        lng: -74.0060,
    });
    const [addressData, setAddressData] = useState({
        city: '',
        state: '',
        country: '',
        address: ''
    })
    const specialityOption = ['Refraction', 'Paediatric', 'Medicines', 'Surgery', 'Orthoptics', 'Artificial Eye', 'LASIK', 'Contact Lenses']
    const [backUpData, setBackUpData] = useState({})
    const [formData, setFormData] = useState({
        firstname: "",
        prefix: "",
        pinCode: "",
        yearsOfExperience: "",
        organization: "",
        qualification: "",
        profession: "",
        speciality: "",
        company: "",
        perDayEyeTest: '',
        slitLampAvailable: ''
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleFileInput = (e) => {
        const file = e.target.files[0];
        setImg(file);
        const imageUrl = URL.createObjectURL(file);
        setProfileImage(imageUrl);
        console.log(imageUrl);

    };

    const handleImage = async () => {
        if (img?.name !== undefined) {
            if (formData?.profile?.fileName) {
                await deleteImg([formData?.profile?.fileName])
            }
            const formData2 = new FormData();
            formData2.append('upload', img);
            try {
                const response = await uploadImg(formData2);
                console.log(response)
                return response?.data?.data[0] || []
            } catch (error) {
                console.log(error);
            }
        } else {
            return formData?.profile
        }
    }


    const handleDocUpload = async () => {
        if (doc?.length == 0) {
            return []
        } else {
            let fileForNotUpload = doc?.filter(res => res?.file?.name == undefined)
            let fileForUpload = doc?.filter(res => res?.file?.name !== undefined)
            let fileForDelete = deleteFile || []
            if (fileForDelete?.length !== 0) {
                await deleteImg(fileForDelete)
            }
            const formData2 = new FormData();
            fileForUpload?.forEach(res => {
                formData2.append('upload', res?.file);
            })
            try {
                const response = await uploadImg(formData2);
                return [...response?.data?.data, ...fileForNotUpload] || []
            } catch (error) {
                console.log(error);
            }
        }
    }


    const handleProfile = async (e) => {
        e.preventDefault();
        if (speciality?.length > 4) {
            toast.error("You can only select max 4 specialities")
            return
        }
        if (speciality?.length == 0) {
            toast.error("Please select specialities")
            return
        }
        setLoading(true)
        const imageURl = await handleImage();
        const documents = await handleDocUpload();
        const payload = {
            date_of_birth: "",
            ...formData,
            ...addressData,
            profile: imageURl,
            degree_document: documents,
            speciality: speciality || [],
            lat_long: {
                lat: selectedPosition?.lat,
                long: selectedPosition?.lng
            }
        }

        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };
        try {
            await profileData(payload, config);

            toast.success("Your Professional Profile has been Updated!")
            setLoading(false)
            setImg({})
            await getProfileData()
            setEdit(true)
        } catch (error) {
            console.log(error);
            setLoading(false);
            const errorMessage = error?.response?.data?.message || 'There are some error occured.'
            toast.error(errorMessage)
        }
    }


    function back() {
        // window.location.href="https://eyemastr.com/"
        navigate("/profileView");
    }

    const onSelect = (selectedList) => {
        setSpeciality(selectedList);
        setPlaceholder("");
    };

    const onRemove = (selectedList) => {
        setSpeciality(selectedList);
        if (selectedList.length === 0) {
            setPlaceholder("Select");
        }
    };

    useEffect(() => {
        const handleGetAddress = async () => {
            setLoading(true);
            try {
                const addr = await getAddressFromLatLng(selectedPosition.lat, selectedPosition.lng, apiKey);
                if (addr && innerData?.formatted_address !== undefined) {
                    const addressComponents = innerData?.address_components;
                    const country = addressComponents.find((comp) => comp.types.includes('country'))?.long_name;
                    const state = addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.long_name;
                    const city = addressComponents.find(
                        (comp) =>
                            comp.types.includes('locality') ||
                            comp.types.includes('administrative_area_level_2')
                    )?.long_name;
                    setAddressData({
                        address: addr,
                        country,
                        state,
                        city,
                    });
                }
            } catch (err) {
                console.error('Error fetching address: ', err);
            } finally {
                setLoading(false);
            }
        };
        handleGetAddress();

    }, [innerData])

    const getAddressFromLatLng = async (lat, lng, apiKey) => {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

        try {
            const response = await fetch(geocodeUrl);
            const data = await response.json();

            if (data.status === 'OK') {
                // Return the formatted address (the first result)
                return data.results[0].formatted_address;
            } else {
                console.error('Geocode failed due to: ', data.status);
                return null;
            }
        } catch (error) {
            console.error('Error with geocoding API: ', error);
            return null;
        }
    };


    function removeDoc(index) {
        let arr = [...doc]
        let deleteFile = arr?.splice(index, 1)
        setDoc(arr)
        let dd = deleteFile?.filter(res => res?.file?.name == undefined)
        let final = dd?.map(res => res?.fileName)
        console.log(final)
        setDeleteFile(pre => {
            return [...pre, ...final]
        })
    }



    async function getProfileData() {
        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };
        setLoader(true)
        try {
            const response = await professionalView(config);
            let info = response?.data?.user;
            console.log(info)
            let data = {
                prefix: info?.prefix || '',
                firstname: info?.firstname || '',
                company: info?.company || '',
                speciality: info?.speciality || [],
                profession: info?.profession || '',
                qualification: info?.qualification || '',
                yearsOfExperience: info?.yearsOfExperience || '',
                perDayEyeTest: info?.perDayEyeTest || '',
                slitLampAvailable: JSON.stringify(info?.slitLampAvailable) || '',
                pinCode: info?.pinCode || ''
            }
            setSpeciality(info?.speciality || [])
            setSpecialityBack(info?.speciality || [])
            setProfileImage(info?.profile?.fileUrl || null)
            setDoc(info?.degree_document || [])
            setAddressData({
                city: info?.city,
                state: info?.state,
                country: info?.country,
                address: info?.address
            })
            setFormData(data)
            setBackUpData(data)
            let location = {
                lat: +(info?.lat_long?.lat),
                lng: +(info?.lat_long?.long)
            }
            setSelectedPosition(location)
            const addr = await getAddressFromLatLng(location.lat, location.lng, apiKey);
            setAddressStr(addr)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getProfileData()
    }, [])



    return (
        <>
            <Modal centered isOpen={openSuccessDialog} size='lg'>
                <ModalBody>
                    <div className=''>
                        <div className='d-flex justify-content-end'>
                            <IoCloseSharp className='pointer closeIcon' onClick={back} />
                        </div>
                        <div className='d-flex justify-content-center'><FaRegCheckCircle className='thankuIcon' /></div>
                        <div className='thankuText mt-3'>Your profile is saved for review</div>
                        <div className='thankuText2'>We will get back to you soon</div>
                        <div className='d-flex justify-content-center mt-2'><button className='btn btn-primary' onClick={back}>Close</button></div>
                    </div>
                </ModalBody>
            </Modal>
            <div className='container'>
                <ViewFile open={viewFileModal} setOpen={setViewFileModal} data={fileForView} setViewData={setFileForView} />
                <Loader open={loader} />

                <form onSubmit={handleProfile}>
                    <div className='d-flex justify-content-between mt-5'>
                        <div className='heading'>Professional Profile</div>
                        {edit ? <button className='btn btn-sm border fw-500 d-flex align-items-center gap-1' type='button' onClick={() => setEdit(false)}><FaRegEdit />Edit</button> : <button className='btn btn-sm fw-500 border' type='button' onClick={() => {
                            setEdit(true)
                            setFormData(backUpData)
                            setSpeciality(specialityBack)
                        }}>Cancel</button>}

                    </div>
                    {/* <div className='text-gray '>Fill in the profile data. It will take a couple of minutes.</div> */}
                    <div className='row mt-4'>
                        <div className='col-12 ' >
                            <div className='d-flex align-items-center flex-md-row flex-sm-row flex-column'>
                                <div className='profilePreview'>
                                    {profileImage ?
                                        <img
                                            src={profileImage}
                                            alt="Profile Preview"
                                            style={{ width: 'auto', height: '100%' }}
                                        />
                                        : <img
                                            src='/images/profile.png'
                                            alt="Profile Preview"
                                            style={{ width: 'auto', height: '100%' }}
                                        />}
                                </div>
                                <div className='ms-4 d-flex flex-column'>
                                    <label className='mb-1'>
                                        Profile Picture<span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        disabled={edit}
                                        className="form-control-file pointer"
                                        name='profile'
                                        onChange={handleFileInput}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-100 mt-4'>
                        <label className='mb-1'>Upload your Degree/Diploma document</label>
                        <input type='file' className='form-control' accept=".pdf, image/*" hidden disabled={edit} placeholder='Upload' id='uploadDoc' multiple name='pinCode' onChange={(e) => {
                            let dd = Object.values(e.target.files)
                            let files = dd?.map(res => {
                                return {
                                    file: res,
                                    fileName: res?.name,
                                    fileUrl: ''
                                }
                            })
                            setDoc(pre => {
                                return [...pre, ...files]
                            })
                        }} />
                        <div className='w-100 border rounded ' >
                            {doc?.length == 0 ? <>
                                <label className='uploadtext pointer py-3' htmlFor='uploadDoc'>
                                    <div className='d-flex justify-content-center'>
                                        <MdOutlineFileUpload className='uploadIcon' />
                                    </div>
                                    <div className='text-center'>Click to upload document</div>
                                </label>
                            </> : <>
                                <div className='fileContainer'>
                                    {doc?.map((res, index) => {
                                        return <div className='fileStyle border rounded p-1' key={index}>
                                            <div className='d-flex justify-content-end w-100'>
                                                <IoMdClose className='pointer fs-4' onClick={() => removeDoc(index)} />
                                            </div>
                                            <div className='text-center mt-2 pointer' onClick={() => {
                                                if (res?.fileUrl !== '') {
                                                    setFileForView(res)
                                                    setViewFileModal(true)
                                                }
                                            }} title={res?.fileName}>{res?.fileName?.length > 15 ? `${res?.fileName?.slice(0, 15)}...` : res?.fileName}</div>
                                        </div>
                                    })}
                                    <div className='fileStyle border rounded p-1'>
                                        <label className='uploadtext w-100 pointer py-3' htmlFor='uploadDoc'>
                                            <div className='d-flex justify-content-center'>
                                                <MdOutlineFileUpload className='uploadIcon' />
                                            </div>
                                            {/* <div className='text-center'>Click to select document</div> */}
                                        </label>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-md-4 col-12 mt-3'>
                            <label className='mb-1'>Prefix<span className='text-danger'>*</span></label>
                            <select
                                name="prefix"
                                className="form-select pointer"
                                placeholder="Select Prefix"
                                disabled={edit}
                                value={formData.prefix}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select Your Prefix
                                </option>
                                <option value="Mr." className='pointer'>Mr.</option>
                                <option value="Ms." className='pointer'>Ms.</option>
                                <option value="Dr." className='pointer'>Dr.</option>
                            </select>
                        </div>
                        <div className='col-md-4 col-sm-8 col-12 mt-3'>
                            <label className='mb-1'>Full Name<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Your Full Name'
                                required
                                className="form-control"
                                disabled={edit}
                                name='firstname'
                                type="text"
                                value={formData.firstname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Eye Care Organization<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Your Company Name'
                                required
                                className="form-control"
                                disabled={edit}
                                name='company'
                                type="text"
                                value={formData.company}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Specialities<span className='text-danger'>*</span></label>
                            <Multiselect
                                options={specialityOption}
                                selectedValues={speciality}
                                isObject={false}
                                onSelect={onSelect}
                                disable={edit}
                                onRemove={onRemove}
                                displayValue="name"
                                placeholder={placeholder}
                                avoidHighlightFirstOption={true}
                                selectionLimit={4}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Profession<span className='text-danger'>*</span></label>
                            <select
                                name="profession"
                                className="form-select pointer"
                                placeholder="Select Your Profession"
                                disabled={edit}
                                value={formData.profession}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select Your Profession
                                </option>
                                <option value="Ophthalmologist" className='pointer'>Ophthalmologist</option>
                                <option value="Optometrist" className='pointer'>Optometrist</option>
                            </select>
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Qualification<span className='text-danger'>*</span></label>
                            <select
                                name="qualification"
                                className="form-select pointer"
                                placeholder="Select Your Qualification"
                                disabled={edit}
                                value={formData.qualification}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select Your Profession
                                </option>
                                <option value="Doctorate" className='pointer'>Doctorate</option>
                                <option value="Masters" className='pointer'>Masters</option>
                                <option value="Graduate" className='pointer'>Graduate</option>
                                <option value="Diploma" className='pointer'>Diploma</option>
                            </select>
                        </div>

                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Years of Experience<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Your Experience'
                                required
                                className="form-control"
                                name='yearsOfExperience'
                                disabled={edit}
                                type="number"
                                value={formData.yearsOfExperience}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Per Day Eye Test in Current Practice<span className='text-danger'>*</span></label>
                            <input
                                placeholder='Enter Your Count'
                                required
                                className="form-control"
                                name='perDayEyeTest'
                                type="number"
                                disabled={edit}
                                value={formData.perDayEyeTest}
                                onChange={handleChange}
                            />

                        </div>
                        <div className='col-md-4 col-12 mt-3'>

                            <label className='mb-1'>Slit Lamp Available<span className='text-danger'>*</span></label>
                            <select
                                name="slitLampAvailable"
                                className="form-select pointer"
                                placeholder="Select the lamp availability"
                                disabled={edit}
                                value={formData.slitLampAvailable}
                                onChange={handleChange}
                            >
                                <option value="" disabled selected>
                                    Select the Lamp availability
                                </option>
                                <option value={true} className='pointer'>Yes</option>
                                <option value={false} className='pointer'>No</option>
                            </select>

                        </div>
                        <div className=' col-md-6 col-12 mt-3'>
                            <label className='mb-1'>{edit ? 'Address' : 'Update Address'}</label>
                            {edit ? <input disabled value={addressstr} className='form-control' /> : <SearchBox setSelectedPosition={setSelectedPosition} setinnerData={setinnerData} />}

                        </div>
                        <div className='col-md-6 col-12 mt-3'>

                            <label className='mb-1'>Pin Code</label>
                            <input className='form-control' disabled={edit} placeholder='Enter pin code' name='pinCode' value={formData?.pinCode} onChange={handleChange} />

                        </div>
                        <div className='col-md-12 mt-3'>
                            <MapComponent
                                selectedPosition={selectedPosition}
                                setSelectedPosition={setSelectedPosition}
                            />
                        </div>

                    </div>
                    <div className='d-flex justify-content-end mt-3 mb-5'>
                        {edit ? '' :
                            <button
                                className='btn btn-primary'
                                type='submit'
                                disabled={loading}
                            >
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    "Submit"
                                )}

                            </button>}
                    </div>
                </form>
            </div>
        </>
    )
}

export default ProfileView