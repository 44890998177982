import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import "./Footer.css";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <>
      {/* <footer className="footer bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer__about">
                                <div className="footer__logo">
                                    <Link to="/"><img src="/images/eyemasterLogo.png" alt="" /></Link>
                                </div>
                                <p>The customer is at the heart of our unique business model</p>
                                <a href="#"><img src="/Assetes/img/payment.png" alt="" /></a>
                            </div>
                        </div>
                        <div className="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                            <div className="footer__widget">
                                <h6>Shopping</h6>
                                <ul>
                                    <li><a href="#">Lens Store</a></li>
                                    <li><a href="#">Trending EyeWears</a></li>
                                    <li><a href="#">Accessories</a></li>
                                    <li><a href="#">Sale</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                            <div className="footer__widget">
                                <h6>Shopping</h6>
                                <ul>
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Payment Methods</a></li>
                                    <li><a href="#">Delivery</a></li>
                                    <li><a href="#">Return & Exchanges</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
                            <div className="footer__widget">
                                <h6>NewLetter</h6>
                                <div className="footer__newslatter">
                                    <p>Be the first to know about new arrivals, look books, sales & promos!</p>
                                    <form action="#">
                                        <input type="text" placeholder="Your email" />
                                        <button type="submit"><span className="icon_mail_alt"></span></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="footer__copyright__text">
                                
                                <p>Copyright ©
                                    2024 Eyemast<FaStar color='#007bff'/>r
                                    All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}

      {/* <footer style={{overflowX:'hidden'}}>
                <div classNameName="footer-container">
                    <div classNameName="sub_containter_footer">
                        <div classNameName="row justify-content-between align-items-center">
                            <div classNameName='col-lg-3 col-md-4 col-sm-4 col-6'>
                            <div classNameName="logo">
                                <img src="/Img/elelogo.png" alt="Eyemast Logo" style={{ width: "80px" }} />
                            </div>
                            </div>
                            <div classNameName='col-lg-3 col-md-4 col-sm-4 col-6'>

                            <div classNameName="footer_left_sub_div">
                                <span><strong>
                                    Phone
                                </strong></span>
                                <span>
                                +91 9654587757
                                </span>
                            </div>
                            </div>
                            <div classNameName='col-lg-3 col-md-4 col-sm-4 col-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3'>

                            <div classNameName="footer_left_sub_div">
                                <span><strong>
                                    Email
                                </strong></span>
                                <span style={{overflowWrap:"break-word"}}>
                                rajesh.kumar@eyemastr.com
                                </span>
                            </div>
                            </div>
                            <div classNameName='col-lg-3 col-md-4 col-sm-4 col-6 mt-lg-0 mt-md-3 mt-sm-3 mt-3'>

                            <div classNameName="footer_left_sub_div">
                                <span><strong>
                                    Address
                                </strong></span>
                                <span>
                                Greater Noida
                                </span>
                            </div>
                            </div>


                        </div>
                        <div classNameName="footer-middle row px-0 mt-4">

                            <div classNameName='col-lg-4 col-md-4 col-sm-4 col-6'>
                                <ul classNameName='ms-0 ps-0'>
                                    <li><a><b>Quick Links</b></a></li>
                                    <li><Link to="https://eyemastr.com/">Home</Link></li>
                                    <li><a href="#">Glasses</a></li>
                                    <li><a href="#">About Us</a></li>
                                    <li><a href="#">Blogs</a></li>
                                    <li><a href="#">Contact Us</a></li>
                                </ul>
                            </div>



                            <div classNameName='col-lg-4 col-md-4 col-sm-4 col-6'>
                                <ul classNameName='ms-0 ps-0'>
                                    <li><a><b>Company</b></a></li>
                                    <li><Link to="/termsandcondition">Terms & Conditions</Link></li>
                                    <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                                    <li><Link to="/refundpolicy">Refund Policy</Link></li>
                                    <li><Link to="/disclaimer">Disclaimer</Link></li>
                                    <li><a href="#">Legal</a></li>
                                </ul>
                            </div>


                            <div classNameName='col-lg-4 col-md-4 col-sm-4 col-6 mt-md-0 mt-sm-0 mt-3'>
                                <ul classNameName='ms-0 ps-0'>
                                    <li><a><b>Rankings</b></a></li>
                                    <li><a href="#">Top Searches</a></li>
                                    <li><a href="#">Trending Sunglasses</a></li>
                                    <li><a href="#">Best Sunglasses</a></li>
                                    <li><a href="#">Top Sunglasses</a></li>
                                    <li><a href="#">Celeb Favorites</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div classNameName="footer-right">
                        <div classNameName="newsletter">
                            <h4>Subscribe to our Newsletter</h4>
                            <form action="">
                                <div classNameName="d-flex justify-content-center">
                                    <input type="email" placeholder="Enter Email" classNameName="" required />
                                </div>
                                <div classNameName="d-flex justify-content-center mt-3">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div classNameName="social-media mt-5">
                            <h4>Connect with Us</h4>
                            <div classNameName="social-icons">
                                <a href="#"><img src="/Img/linldn.png" alt="LinkedIn" /></a>
                                <a href="#"><img src="/Img/twitter.png" alt="twitter" /></a>
                                <a href="#"><img src="/Img/facebook.png" alt="Facebook" /></a>
                                <a href="#"><img src="/Img/instagram.png" alt="Instagram" /></a>
                                <a href="#"><img src="/Img/yourname.png" alt="Pinterest" /></a>
                                <a href="#"><img src="/Img/noNameicon.png" alt="Tiktok" /></a>
                                <a href="#"><img src="/Img/youtube.png" alt="Youtube" /></a>

                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
      <footer className="footer pt-md-5 pt-3">
        <div className=" container" style={{ padding: "25px", }}>
          <div className="row">
            {/* Logo and Social Links */}
            <div className="col-lg-4 col-12 mb-md-3 mb-ms-3">
              <img alt="" src="/Img/elelogo.png" style={{ width: "80px" }} />
              <div className="social-icons mt-3 ">
                <Link to="#" className="link_style">
                  <img src="/images/facebook.png" alt="Facebook" width="30" />
                </Link>
                <Link to="#" className="link_style">
                  <img src="/images/twitter.png" alt="Twitter" width="30" />
                </Link>
                <Link to="#" className="link_style">
                  <img src="/images/instagram.png" alt="Instagram" width="30" />
                </Link>
                <Link to="#" className="link_style">
                  <img src="/images/youtube.png" alt="YouTube" width="30" />
                </Link>
                <Link to="#" className="link_style">
                  <img src="/images/linkdin.png" alt="LinkedIn" width="30" />
                </Link>
              </div>
            </div>

            {/* Company Links */}
            <div className="col-lg-2 col-6 mb-md-0 mt-md-0 mt-lg-0 mt-sm-3 mt-3">
              <h5 className="mb-2">Company</h5>
              <ul className="list-unstyled footer-links">
                <li className="mb-2">
                  <Link to="/termsandcondition" className="link_style ">
                    Terms & Conditions
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/privacypolicy" className="link_style">
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/refundpolicy" className="link_style">
                    Refund Policy
                  </Link>
                </li>
              </ul>
            </div>

            {/* Quick Links */}
            <div className="col-lg-2 col-6 mb-md-0 mt-md-0 mt-lg-0 mt-sm-3 mt-3">
              <h5 className="mb-2">Quick Links</h5>
              <ul className="list-unstyled footer-links">
                <li className="mb-2">
                  <Link to="https://eyemastr.com/" className="link_style">
                    Home
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="https://eyemastr.com/product-category/all-category/contact-lenses/" className="link_style">
                    Contact Lenses
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="https://eyemastr.com/blogs/" className="link_style">
                    Blogs
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="https://eyemastr.com/contact/" className="link_style">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* Company Info */}
            <div className="col-lg-4 col-12">
              <h5 className="mb-2">Company Info</h5>
              <ul className="list-unstyled">
                <li className="mb-2 company_info">
                <img src="/images/contact.png" alt="Facebook" width="15" style={{marginRight:"10px"}} /> 
                <span>+971 566658103</span>
                </li>
                <li className="mb-2 company_info">
                <img src="/images/message.png" alt="Facebook" width="13"  style={{marginRight:"10px"}}/>
                  <span>support@eyemastr.com </span>
                </li>
                <li className="mb-2 company_info">
                <img src="/images/location.png" alt="Facebook" width="10" style={{marginRight:"10px"}} /> 
                <span>Dubai UAE</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Disclaimer */}
        <div className="disclaimer">
          <Link to="/disclaimer" className="link_style">
            Disclaimer
          </Link>
        </div>
      </footer>
    </>
  );
}
