import React, { useEffect, useState } from "react";
import "./Appoinment.css";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDoctorsDetailById,
  appAppoinmentByuser,
} from "../../Services/AllApi";
import Loader from "../../Components/Loader/Loader";
import toast from 'react-hot-toast';

export default function MakeAppoinment() {
  const [loader, setLoader] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");
  const [getDetailsById, setGetDetailsById] = useState([]);

  const [activeTab, setActiveTab] = useState("1");
  const navigation = useNavigate();
  const params = useParams();

  const [selectDay, setSelctDay] = useState("");
  const [selectTime, setselectTime] = useState("");

  const [gettimeSlot, setGetTimeSlot] = useState();

  function formatDateTime(date, time) {
    // Combine date and time strings
    const dateTimeString = `${date} ${time}`;

    // Parse into a JavaScript Date object
    const dateTime = new Date(dateTimeString);

    // Format each part with padding
    const year = dateTime.getUTCFullYear();
    const month = String(dateTime.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(dateTime.getUTCDate()).padStart(2, "0");
    const hours = String(dateTime.getUTCHours()).padStart(2, "0");
    const minutes = String(dateTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateTime.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(dateTime.getUTCMilliseconds()).padStart(3, "0");

    // Construct the final formatted date-time string
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
  }

  function getDayOfWeek(dateString) {
    const date = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = date.getDay(); // returns 0 for Sunday, 1 for Monday, etc.
    return daysOfWeek[dayOfWeek];
  }

  function getSlot(date) {
    let day = getDayOfWeek(date);
    let sloteInfo = getDetailsById.availability?.timeSlots.filter(
      (e) => e.dayOfWeek.toString() === day.toString()
    );
    setGetTimeSlot(sloteInfo[0]);
    console.log(sloteInfo[0]);
  }

  const getDataById = async () => {
    try {
      let res = await getDoctorsDetailById(params?.id);
      console.log(res?.data);
      setGetDetailsById(res?.data?.user);
    } catch (err) {
      console.log(err);
    }
  };


  let getData = JSON.parse(sessionStorage.getItem("selectedConcerns"));
  const addAppoinmentDatas = async () => {
    let payload = {
      professionalId: params.id,
      appointmentDateTime: formatDateTime(selectDay, selectTime),
      serviceType: "offline",
      location: "123 Main St, Suite 400, Cityville, State 12345",
      diagnoses: getData.map((e) => {
        return {
          content: e,
        };
      }),
    };
    setLoader(true)
    try {
      let res = await appAppoinmentByuser(payload);
      setLoader(false)
      toast.success("Your Appoinment Schedule Successfully")

    } catch (err) {
      console.log(err);
      toast.error("Please register or login again.");
      setLoader(false)
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getDataById();
  }, []);

  return (
    <>
      <Loader open={loader} />
      <div className="Appointement_container">
        <div>
          <button
            className="btn btn-light"
            onClick={() => {
              navigation(`/professional-detail/${params.id}`);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="pe-3"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </button>
        </div>
        <div className="stepper mt-3">
          <Nav className="stepper-nav">
            <NavItem>
              <NavLink
                className={classnames("step", { active: activeTab === "1" })}
                onClick={() => toggle("1")}
              >
                <div className="step-circle">1</div>
                <div className="step-label">Date & Time</div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames("step", { active: activeTab === "2" })}
                onClick={() => toggle("2")}
              >
                <div className="step-circle">2</div>
                <div className="step-label">Payment</div>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames("step", { active: activeTab === "3" })}
                onClick={() => toggle("3")}
              >
                <div className="step-circle">3</div>
                <div className="step-label">Scheduled</div>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <TabContent activeTab={activeTab} className="mt-4">
          <TabPane tabId="1">
            <div className="row">
              <div className="col-md-6">
                <label className="labelHeading">Select Appointment Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    setSelctDay(e.target.value);
                    getSlot(e.target.value);
                  }}
                />
              </div>
              {/* <div className="col-md-6 mt-md-0 mt-lg-0 mt-sm-3">
            <label className='labelHeading'>Select Time</label>
            <input type="time"  className="form-control"/>
          </div> */}
            </div>
            <div className="time-slots-container mt-4">
              <div className="day-name">{gettimeSlot?.dayOfWeek}</div>
              <div className="time-inputs">
                {gettimeSlot?.slots?.map((slot, index) => (
                  <div className="">
                    <input
                      type="text"
                      value={`${slot.startTime} - ${slot.endTime}`} // Displaying both start and end time
                      readOnly
                      className="time-slot-input pointer"
                      onClick={() => {
                        setselectTime(slot.startTime);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <button
                className="appointment-button-style"
                onClick={() => {
                  addAppoinmentDatas();
                }}
              >
                Make An Appointment
              </button>
            </div>
          </TabPane>
          <TabPane tabId="2"></TabPane>
        </TabContent>
      </div>
    </>
  );
}
