import React, { useEffect, useState } from "react";
import "./NearMeEyemaster.css";
import { getAllApprovedProfessionals, getAllDoctorsList } from "../../Services/AllApi";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import toast from "react-hot-toast";
import { IoIosArrowBack } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";

import RatingStars from "../../Components/RatingStars/RatingStars";


export default function NearMeEyemaster2() {
  const navigation = useNavigate();
  const [getAlldoctors, setGetAllDoctors] = useState([]);
  const [filteredProfessionals, setFilteredProfessionals] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userCity, setUserCity] = useState("");
  const [loader, setLoader] = useState(false);

  const userCurrentPosition = { latitude: 40.7128, longitude: -74.006 }; // Default position (New York)

  const calculateDistance = ({ lat1, lon1, lat2, lon2 }) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const getAllData = async () => {
    setLoader(true)
    try {
      let res = await getAllApprovedProfessionals();
      const filteredByDistance = checkInRange(res.data);
      setGetAllDoctors(filteredByDistance);
      setFilteredProfessionals(filteredByDistance);
      console.log(filteredByDistance);
      setLoader(false)
    } catch (err) {
      console.log(err);
      setLoader(false)
    }
  };

  const checkInRange = (doctors) => {
    return doctors.filter((doctor) => {
      if (doctor.lat_long && doctor.lat_long.lat && doctor.lat_long.long) {
        const distance = calculateDistance({
          lat1: userCurrentPosition.latitude,
          lon1: userCurrentPosition.longitude,
          lat2: parseFloat(doctor.lat_long.lat),
          lon2: parseFloat(doctor.lat_long.long),
          // lat2: userCurrentPosition.latitude,
          // lon2: userCurrentPosition.longitude,
        });
        console.log(distance);
        return distance < 15; // Filter doctors within 15 km
      }
      return false;
    });
  };

  const handleSearch = (e) => {
    let text = e.target.value
    const filtered = getAlldoctors?.filter(
      (doctor) =>
        doctor.firstname
          .trim()
          .replaceAll(" ", "")
          .toLowerCase()
          .startsWith(text.trim().toLowerCase()) || text.trim() === ""
    );
    setFilteredProfessionals(filtered);
    setSearchText(text);
  };

  const getCityFromCoordinates = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      const city =
        data.address?.city || data.address?.town || data.address?.village;
      setUserCity(city);
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          userCurrentPosition.latitude = latitude;
          userCurrentPosition.longitude = longitude;
          getCityFromCoordinates(latitude, longitude);
          getAllData();
        },
        (error) => {
          console.error("Error getting user location:", error);
          toast.error("Turn on location to find nearby eyecare professionals.")
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      toast.error("Geolocation is not supported by this browser.")
    }

  }, []);




  return (
    <>
      <Loader open={loader} />
      <div className="container">

        <div className="mt-3 d-flex justify-content-between align-items-center">
          <Link className="btn border p-1 px-2" to="/symptoms"
          >
            <IoIosArrowBack size={18} />
          </Link>
          <div className="fw-500 fs-5 d-md-none d-block">Nearby Professionals</div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div className="fw-500 fs-2 d-md-block d-none mt-3">Nearby Professionals</div>
        <div className="row">
          <div className="mt-3 col-md-4 position-relative">
            <IoSearchOutline className="iconPosition" />
            <input className="form-control border" value={searchText} onChange={handleSearch} style={{ paddingLeft: '35px' }} placeholder="Search" />
          </div>
        </div>
        <div className="mb-4 mt-3">
          <div className="main_conatiner_near_eyemaster">
            <div className="professionals-list mt-3">
              {loader ? '' : filteredProfessionals.length > 0 ? (
                <>
                  {filteredProfessionals.map((e) => (
                    <div
                      className="professional-card pointer"
                      onClick={() => {
                        navigation(`/professional-detail/${e._id}`);
                      }}
                      key={e.id}
                    >
                      <div className="professional_card_left">
                        <img
                          src={e?.profile?.fileUrl}
                          alt={e.name}
                          className="profile-image"
                        />
                        <div className="professional-info">
                          <h3>
                            {e?.prefix}
                            {' '}{e?.firstname}
                          </h3>
                          <p>
                            {e.profession} | {e.qualification}
                          </p>
                          <p>{e?.yearsOfExperience} years of experience overall </p>
                          {/* <p>{e?.city},{e?.company}</p> */}
                          <RatingStars averageRating={e?.use_admin_rating ? e?.admin_rating?.avg : e?.averageRating} ratingCount={e?.use_admin_rating ? e?.admin_rating?.count : e?.totalRatings} />
                        </div>
                      </div>
                      <div className="view_profile_button d-md-block d-none" >
                        View Profile
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <p>{searchText?.length > 0 ? 'No result found' : 'Currently, no professionals are available nearby. Please try again later.'}</p>
                </>
              )}

            </div>
          </div>

        </div>
      </div>
    </>
  );
}


