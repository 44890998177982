import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { professionalLogin } from "../../Services/AllApi";
import toast from "react-hot-toast";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa";


const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  };

  const handleProfLogin = async (e) => {
    e.preventDefault();
    const payload = {
      email: formData.email,
      password: formData.password,
    };
   
    try {
      setLoading(true);
      const response = await professionalLogin(payload);
     
      const userInfo = {
        id: response.data._id,
        token: response.data.token,
        role: response.data.role,
      };
      localStorage.setItem("UserDetail", JSON.stringify(userInfo));
      toast.success("Welcome back to Eyemaster!");
      setFormData("");
      if(response?.data?.firstname){
        // window.location.reload()
        navigate("/profileView");
        // window.location.href = "https://eyemastr.com/";
      }else{
        navigate("/professionalProfile");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message;
      toast.error(errorMessage);
      setFormData({
        email: "",
        password: "",
      });
      console.log(error);
    }
  };

  return (
    <div className={`registrationBanner p-md-5 p-3  `}>
      <div className="container">
        <div className="mt-5">
          <form onSubmit={handleProfLogin}>
            <div className="row">
              <div className="col-lg-6 col-md-10 col-12 border rounded bg-white p-4">
                <div className="mb-3 heading">
                Log in to access your Eyemast
                <FaStar color="#007bff" />r account
                </div>
                <div className="col-12 form-group mt-4">
                  <label className="mb-1">Email</label>
                  <input
                    type="text"
                    placeholder="Enter your Email"
                    required
                    name="email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 form-group mt-3">
                  <label className="mb-1">Password</label>
                  <div className="input-group position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your Password"
                      required
                      name="password"
                      className="form-control py-2 rounded"
                      value={formData.password}
                      onChange={handleChange}
                      style={{ paddingRight: "45px" }}
                    />
                    <span
                      className="position-absolute iconStyle"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                    </span>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <button
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <div className="mb-4 mt-3 text-center">
                  Don't have an account ?{" "}
                  <span
                    className="pointer"
                    onClick={() => {
                      navigate("/signup");
                    }}
                    style={{ color: "#007bff" }}
                  >
                    Sign up
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
