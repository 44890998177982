import React, { useEffect, useState } from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { getProfileData, updatePaymentstatus, appAppoinmentByuser } from '../../Services/AllApi';
import CryptoJS from 'crypto-js';

export default function PaymentAppointmentSuccess() {
    const navigate = useNavigate()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [loader, setLoader] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState(true)
    const encrypt = decodeURIComponent(urlParams.get("encrypt"));
    let data = sessionStorage.getItem("appointmentDetails")


    useEffect(() => {
        const id = urlParams.get('id');
        const paymentIntent = urlParams.get('payment_intent');
        const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');
        const redirectStatus = urlParams.get('redirect_status');

        if (redirectStatus) {
            if (redirectStatus == 'succeeded') {
                setPaymentStatus(true)
                addDetails(true, paymentIntent, 'succeeded', id)
            } else {
                setPaymentStatus(false)
                addDetails(false, paymentIntent, 'failed', id)
            }

        } else {

        }

    }, [])

    const appointment = async (id) => {
        let parseData = JSON.parse(data)
        let newData = {
            ...parseData,
            "paymentId": id

        }
        try {
            await appAppoinmentByuser(newData)

        } catch (err) {
            console.log(err)
        }
    }


    async function addDetails(status, tId, status2, userId) {
        let userData = await getProfileData(userId)
        let data = userData?.data?.user
        try {
            let payload = {
                isPaymentComplete: status,
                paymentAmount: data?.country == "India" ? 100 : 20,
                paymentCurrency: data?.country == "India" ? "INR" : "AED",
                paymentDate: new Date(),
                transactionId: tId,
                paymentMethod: "strip",
                paymentStatus: status2,
                paymentFor: "appointment",
            };

            let res = await updatePaymentstatus(userId, payload)
            await appointment(res?.data?.paymentDetails?._id)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }




    return (
        <>
            <Loader open={loader} />
            <div className='w-100 py-5 my-md-5 my-sm-5 my-0 ' style={{ overflowX: 'hidden' }}>
                <div className='row justify-content-center pb-md-5 pb-sm-5 pb-0 mb-md-5 mb-sm-5 mb-0'>
                    <div className='col-md-6'>
                        <div className='w-100 py-5'>
                            <div className='d-flex justify-content-center pb-2'>
                                {paymentStatus ? <FaRegCheckCircle size={45} color='#007bff' className='text-center' /> : <IoMdInformationCircleOutline size={45} className='text-danger text-center' />}
                            </div>
                            <h3 className='text-center'>{paymentStatus ? 'Payment successful' : 'Payment unsuccessful'}</h3>
                            <div className='text-center text-gray mt-1'>{paymentStatus ? 'Your appointment is booked.' : 'Your payment was unsuccessful.'}</div>
                            <div className='text-center text-gray'>{paymentStatus ? 'You will receive a confirmation on your email after a response from your eye care professional.' : 'Please check your details and try again.'}</div>
                            <div className='d-flex justify-content-center mt-3'>
                                <a className='btn btn-sm btn-primary' href='https://eyemastr.com/'>Back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
