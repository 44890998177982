import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import { FaPlus } from "react-icons/fa";
import { AiTwotoneDelete } from "react-icons/ai";
import toast from 'react-hot-toast';
import { addAvailabillity, getAvailabillity } from '../../Services/AllApi';
import Loader from '../../Components/Loader/Loader';


export default function Availabillity() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    let weekDays = [
        { dayOfWeek: "Monday", slots: [] },
        { dayOfWeek: "Tuesday", slots: [] },
        { dayOfWeek: "Wednesday", slots: [] },
        { dayOfWeek: "Thursday", slots: [] },
        { dayOfWeek: "Friday", slots: [] },
        { dayOfWeek: "Saturday", slots: [] },
        { dayOfWeek: "Sunday", slots: [] }
    ]

    function addSlot(index) {
        let arr = [...data]
        let slotsArr = arr[index].slots
        let slot = { startTime: "", endTime: "" }
        slotsArr.push(slot)
        setData(arr)

    }

    function removeSlot(index, ind) {
        let arr = [...data]
        let slotsArr = arr[index].slots
        slotsArr.splice(ind, 1)
        setData(arr)
    }

    function handleChange(e, index, ind) {
        let { name, value } = e.target
        let arr = [...data]
        arr[index].slots[ind][name] = formatTimeTo12Hour(value)
        setData(arr)
    }

    function checkEmptySlots(weekData) {
        for (let day of weekData) {
            for (let slot of day.slots) {
                if (!slot.startTime) {
                    return `Please fill the empty opens at slot for ${day.dayOfWeek}.`;
                } else if (!slot.endTime) {
                    return `Please fill the empty closes at slot for ${day.dayOfWeek}.`;
                }
            }
        }
        return "";
    }

    async function submitData() {
        let err = checkEmptySlots(data)
        if (err !== '') {
            toast.error(err)
            return
        }
        let payload = {
            timeSlots: data
        }
        setLoader(true)
        try {
            await addAvailabillity(payload)
            toast.success('Your availabillity is updated.')
        } catch (err) {
            console.log(err)
            toast.error('Some error occupide please check')
        } finally {
            setLoader(false)
        }

    }

    async function getAllData() {
        setLoader(true)
        try {
            let res = await getAvailabillity()
            let response = res?.data?.timeSlots || weekDays
            setData(response)

        } catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getAllData()
    }, [])

    const formatTimeTo12Hour = (time) => {
        let [hours, minutes] = time.split(':');
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    };

    const convertTo24HourFormat = (time) => {
       
        if (/^\d{2}:\d{2}$/.test(time)) {
          return time; 
        }
      
      
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':');
      
      
        hours = parseInt(hours, 10);
      
        if (modifier === 'PM' && hours !== 12) {
          hours += 12; 
        } else if (modifier === 'AM' && hours === 12) {
          hours = 0; 
        }
      
        return `${String(hours).padStart(2, '0')}:${minutes}`;
      };


    return (
        <>
            <Loader open={loader} />
            <div className='container'>
                <div className="mt-md-4 mt-3 d-flex justify-content-between align-items-center">
                    <button className="btn border p-1 px-2" onClick={() => navigate('/profileView')}> <IoIosArrowBack size={18} /></button>
                    <div className="fw-500 f-18">Set Availability</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div className='fs-5 fw-bold mt-3'>Set Working Hours</div>
                <div className='text-gray mt-md-3 mt-2'>Check the days you're available and enter your start and end times for each day.</div>
                <div className='my-3'>
                    <UncontrolledAccordion defaultOpen={[
                        '0'

                    ]} stayOpen >
                        {data?.map((res, index) => {
                            return (
                                <>
                                    <AccordionItem key={index}>
                                        <AccordionHeader targetId={index.toString()}>
                                            <div className='fw-500'>{res?.dayOfWeek}</div>
                                        </AccordionHeader>
                                        <AccordionBody accordionId={index.toString()}>
                                            <div className='row justify-content-center'>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className='col-lg-10 col-12 d-flex justify-content-end'>
                                                            <button className='btn btn-sm border rounded-pill fw-500 d-flex align-items-center gap-1' onClick={() => addSlot(index)}><FaPlus /> Add Slot</button>
                                                        </div>
                                                    </div>
                                                    {res?.slots?.length !== 0 ? <>
                                                        {res?.slots?.map((item, ind) => {
                                                            return (
                                                                <>
                                                                    <div className='row mt-2' key={ind}>
                                                                        <div className='col-5'>
                                                                            <label className='f-14 fw-500 mb-1'>Opens At</label>
                                                                            <input type='time' name='startTime' value={convertTo24HourFormat(item.startTime)} className='form-control' onChange={(e) => { handleChange(e, index, ind) }} />
                                                                        </div>
                                                                        <div className='col-5'>
                                                                            <label className='f-14 fw-500 mb-1'>Closes At</label>
                                                                            <input type='time' name='endTime' value={convertTo24HourFormat(item.endTime)} className='form-control' onChange={(e) => handleChange(e, index, ind)} />
                                                                        </div>
                                                                        <div className='col-2'>
                                                                            <div className='mb-1'>&nbsp;</div>
                                                                            <div className=' d-flex align-items-center h-50'><AiTwotoneDelete size={24} className='text-danger pointer' onClick={() => removeSlot(index, ind)} /></div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                        : <div className='text-center text-gray fw-500 mt-2'>No slots added on {res?.dayOfWeek}.</div>}

                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </AccordionItem>
                                </>
                            )
                        })}
                    </UncontrolledAccordion>
                </div>
                <div className='row justify-content-end pb-4 pt-2'>
                    <div className='col-lg-2 col-md-3 col-sm-4 col-12'>
                        <button className='btn btn-primary w-100 fw-500' onClick={submitData}>Save Changes</button>
                    </div>
                </div>
            </div>
        </>
    )
}
