import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';
import { IoIosArrowBack } from "react-icons/io";
import { getAvailabilityData, rescheduleAppointment } from '../../Services/AllApi';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';


const numberOfDays = 60;


export default function RescheduleAppointment() {
    const userDetails = JSON.parse(localStorage.getItem('UserDetail'));
    const navigation = useNavigate();
    const params = useParams()
    const [loader, setLoader] = useState(false)
    const [dates, setDates] = useState([]);
    const [id, setId] = useState('')
    const [getSlots, setgetSlots] = useState([]);
    const [selectSlots, setSelectSlots] = useState("");
    const [selectedDate, setSelectedDate] = useState("Today");
    const [selectDate, setSelectDate] = useState({
        name: "Today",
        date: new Date(),
    });

    const generateDates = () => {
        const today = new Date();
        const dates = [];
        for (let i = 0; i < numberOfDays; i++) {
            const date = new Date();
            date.setDate(today.getDate() + i);
            const dayName =
                i === 0
                    ? "Today"
                    : i === 1
                        ? "Tomorrow"
                        : date.toLocaleDateString("en-US", {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                        });
            dates.push({ name: dayName, date });
        }
        return dates;
    };

    const getAllAvailabilityData = async (date) => {
        const payloadData = {
            professionalId: id,
            date: moment(date).utc().format("YYYY/MM/DD"),
        };
        setLoader(true);
        try {
            const res = await getAvailabilityData(payloadData);
            setgetSlots(res.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoader(false);
        }
    };



    useEffect(() => {
        if (userDetails?.token) {
            let token = userDetails?.token || ''
            let decoded = {}
            if (token) {
                decoded = jwtDecode(token);
            }
            setId(decoded?.id)
        } else {
            setId('')
        }
    }, []);

    useEffect(() => {
        getAllAvailabilityData(new Date());
        setDates(generateDates());
    }, []);

    const handleDateClick = (date) => {
        setSelectedDate(date.name);
        setSelectDate(date);
        getAllAvailabilityData(date.date);
    };

    function createDateWithTime(dateString, timeString) {
        // Parse the ISO date string into a moment object
        const dateTime = moment(dateString).utc();

        // Parse the time string and override the time in the moment object
        const time = moment(timeString, 'hh:mm A');
        dateTime.set({
            hour: time.hours(),
            minute: time.minutes(),
            second: 0,
            millisecond: 0
        });

        // Return as a JavaScript Date object
        return dateTime.toDate();
    }


    const appointmentBook = async () => {

        if (selectSlots) {
            const payload = {
                id: params?.id,
                appointmentDateTime: createDateWithTime(selectDate.date, selectSlots),
            };
            setLoader(true)
            try {
                await rescheduleAppointment(payload)
                toast.success('This appointment is reschedule.')
                navigation('/appointments')
            } catch (err) {
                console.log(err)
            } finally {
                setLoader(false)
            }

        } else {
            toast.error("Please select a time slot before proceeding to book appointment.")
        }
    }


    return (
        <>
            <Loader open={loader} />
            <div className='container'>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <button
                        className="btn border p-1 px-2"
                        onClick={() => navigation("/appointments")}
                    >
                        <IoIosArrowBack size={18} />
                    </button>
                    <div className="fw-500 f-18">Reschedule Appointment</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div className="date-slider mt-2">
                    {dates?.map((date) => (
                        <div
                            key={date.name}
                            className={`tab ${selectedDate === date?.name ? "active" : ""
                                }`}
                            onClick={() => handleDateClick(date)}
                        >
                            <p className="tab-title">{date.name}</p>
                        </div>
                    ))}
                </div>
                <div style={{ minHeight: '30vh' }}>
                    <div className="slot-buttons pb-5" >
                        {getSlots?.length > 0 ? (
                            getSlots?.map((slot, index) => (
                                <React.Fragment key={index}>
                                    {slot.isBooked ? (
                                        <button className="slot_disable" disabled>
                                            {slot?.startTime}
                                        </button>
                                    ) : (
                                        <button
                                            className={`slot ${selectSlots === slot?.startTime ? "selected" : ""
                                                }`}
                                            onClick={() => { setSelectSlots(slot?.startTime) }}
                                        >
                                            {selectSlots === slot?.startTime
                                                ? slot?.startTime
                                                : slot?.startTime}
                                        </button>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <p className="slot_not_available">
                                No slots are currently available. Please choose another
                                date.
                            </p>
                        )}
                    </div>
                </div>
                <div className="row justify-content-end mb-4 mt-5">
                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 mt-5'>
                        <button className="btn btn-primary rounded-pill f-14 w-100 my-5" onClick={appointmentBook}>Reschedule</button>
                    </div>
                </div>
            </div>
        </>
    )
}
