import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Upcoming from './MyUpcoming';
import Completed from './MyCompleted';
import Loader from '../../Components/Loader/Loader';
import Canceled from './MyCanceled';



export default function MyAppointments() {
    const navigation = useNavigate();
    const [loader, setLoader] = useState(false)
    const [activeTab, toggleTab] = useState('0')


    return (
        <>
            <Loader open={loader} />
            <div className='container'>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <button
                        className="btn border p-1 px-2"
                        onClick={() => navigation("/view-profile")}
                    >
                        <IoIosArrowBack size={18} />
                    </button>
                    <div className="fw-500 f-18">Manage Appointments</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div className='mt-3 mb-5'>
                    <div className='w-100' style={{overflowX:'auto'}}>
                    <Nav vertical={false} className={`border-bottom flex flex-nowrap`}>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-500 ${activeTab == "0" ? 'activeTab2' : ""}  pointer px-4`}
                                onClick={() => toggleTab("0")}>
                                <div className='d-flex justify-content-between align-items-center'>Upcoming</div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-500 ${activeTab == "1" ? 'activeTab2' : ""}  pointer px-4`}
                                onClick={() => toggleTab("1")}>
                                <div className='d-flex justify-content-between align-items-center'>Completed</div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-500 ${activeTab == "2" ? 'activeTab2' : ""}  pointer px-4`}
                                onClick={() => toggleTab("2")}>
                                <div className='d-flex justify-content-between align-items-center'>Cancelled</div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    </div>

                    <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
                        <TabPane tabId="0">
                            {activeTab == '0' ? <Upcoming setLoader={setLoader} /> : ''}
                        </TabPane>
                        <TabPane tabId="1">
                            {activeTab == '1' ? <Completed setLoader={setLoader} /> : ''}
                        </TabPane>
                        <TabPane tabId="2">
                            {activeTab == '2' ? <Canceled setLoader={setLoader} /> : ''}
                        </TabPane>
                    </TabContent>

                </div>

            </div>
        </>
    )
}
