import React, { useEffect, useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, ModalHeader, UncontrolledAccordion } from 'reactstrap';
import { changeAppointmentStatus, getRequestedAppointments } from '../../Services/AllApi';
import { MdOutlineWatchLater } from "react-icons/md";
import { symptomToDiseaseMap } from '../../Assets/constants';
import moment from 'moment';
import { CiCircleInfo } from "react-icons/ci";
import toast from 'react-hot-toast';
import { BiFilterAlt } from "react-icons/bi";


const numberOfDays = 60;


export default function Requested({ setLoader }) {
    const [data, setData] = useState([])
    const [dates, setDates] = useState([]);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [allAppointments, setAllAppointments] = useState([])
    const [selectedDate, setSelectedDate] = useState(moment().utc().format('yyyy-MM-DD'));
    const [appointmentId, setAppointmentId] = useState('')
    const [appointmentStatus, setAppointmentStatus] = useState('')
    const [selectDate, setSelectDate] = useState({
        name: "Today",
        date: new Date(),
    });

    const generateDates = () => {
        const today = new Date();
        const dates = [];
        for (let i = 0; i < numberOfDays; i++) {
            const date = new Date();
            date.setDate(today.getDate() + i);
            const dayName =
                i === 0
                    ? "Today"
                    : i === 1
                        ? "Tomorrow"
                        : date.toLocaleDateString("en-US", {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                        });
            dates.push({ name: dayName, date });
        }
        return dates;
    };




    const handleDateClick = (date) => {
        setSelectedDate(date.name);
        setSelectDate(date);
        getAllAvailabilityData(date.date);
    };


    async function getAllData() {
        try {
            setLoader(true)
            let res = await getRequestedAppointments()
            let response = res?.data || []
            setAllAppointments(response)
            setData(response)
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }

    const getAllAvailabilityData = async (date) => {
        let latest = allAppointments?.filter((res) => moment(res?.appointmentDateTime).utc().format('DD-MM-YYYY') == moment(date).utc().format('DD-MM-YYYY'))
        setData(latest)
    };



    // useEffect(() => {
    //     getAllAvailabilityData(selectDate?.date);
    // }, [allAppointments]);

    useEffect(() => {
        getAllData();
        setDates(generateDates());
    }, []);

    function getDiseasesFromSymptoms(symptoms) {
        const diseases = new Set();

        symptoms.forEach(symptom => {
            if (symptomToDiseaseMap[symptom]) {
                symptomToDiseaseMap[symptom].forEach(disease => diseases.add(disease));
            }
        });

        return Array.from(diseases);
    }


    function getDesease(data) {
        let getData = data

        if (!Array.isArray(getData)) {
            console.error("getData is not an array");
            getData = []; // Fallback to an empty array if needed
        }
        let arr = getDiseasesFromSymptoms(getData)
        return arr

    }


    async function updateData() {
        let payload = {
            status: appointmentStatus,
            id: appointmentId
        }
        setLoader(true)
        let msg = ''
        if (appointmentStatus == 'cancel') {
            msg = 'Booking cancelled.'
        } else {
            msg = 'Booking accepted and added to upcoming.'
        }
        try {

            await changeAppointmentStatus(payload)
            setOpen(false)
            setAppointmentId('')
            setAppointmentStatus('')
            await getAllData()
            toast.success(msg)
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }


    function openDialog(type, id) {
        setOpen(true)
        setAppointmentId(id)
        setAppointmentStatus(type)
    }

    const filterData = async (e) => {
        e.preventDefault()
        setOpen2(false)
        setIsFilter(true)
        let filterData = allAppointments?.filter(res => moment(res?.appointmentDateTime).utc().format('yyyy-MM-DD') == selectedDate)
        setData(filterData)
    };

    function clearFilter() {
        setData(allAppointments)
        setIsFilter(false)
        setSelectedDate(moment().utc().format('yyyy-MM-DD'))
    }

    return (
        <>
            <Modal isOpen={open} centered>
                <ModalBody>
                    <div className='py-3 mt-2'>
                        <div className='d-flex justify-content-center'><CiCircleInfo className='text-danger' size={50} /></div>
                        <div className='text-center fw-500 mt-3'>{appointmentStatus == 'cancel' ? 'Are you sure want to cancel this appointment?' : 'Are you sure want to confirm this appointment?'}</div>
                    </div>
                    <div className='d-flex justify-content-end gap-3 mt-2'>
                        <button className='btn btn-sm border fw-500' onClick={() => setOpen(false)}>Cancel</button>
                        <button className='btn btn-sm btn-primary border fw-500' onClick={updateData}>Submit</button>
                    </div>
                </ModalBody>

            </Modal>
            <Modal isOpen={open2} centered>
                <ModalHeader>Filter by date</ModalHeader>
                <ModalBody>
                    <form onSubmit={filterData}>
                        <div className='pb-3'>
                            <label className='fw-500'>Select Date</label>
                            <input type='date' className='form-control' required placeholder='Select Date' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                        </div>
                        <div className='d-flex justify-content-end gap-3 mt-2'>
                            <button className='btn btn-sm border fw-500' type='button' onClick={() => setOpen2(false)}>Cancel</button>
                            <button className='btn btn-sm btn-primary border fw-500' type='submit'>Submit</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {isFilter ?
                <div className='d-flex justify-content-end mb-3'>
                    <button className='btn btn-sm f-14 fw-500 border d-flex align-items-center gap-1' onClick={clearFilter}>Clear Filter</button>
                </div> : ''}
            <div className=''>
                {data?.length == 0 ? <>
                    <div className='noDataScreen'>
                        <div>
                            <div className='w-100 d-flex justify-content-center'><MdOutlineWatchLater size={60} className='text-success' /></div>
                            <div className='text-center fw-500 fs-5 mt-2'>No Requested Appointments</div>
                            <div className='text-center f-14 mt-2 text-gray'>No appointment requests are available for this day. Please check another day.</div>
                        </div>
                    </div>

                </> : <>
                    <div style={{ minHeight: '40vh' }}>
                        {!isFilter ?
                            <div className='d-flex justify-content-end mb-3'>
                                <button className='btn btn-sm f-14 fw-500 border d-flex align-items-center gap-1' onClick={() => setOpen2(true)}><BiFilterAlt /> Filter</button>
                            </div> : ''}
                        <UncontrolledAccordion defaultOpen={[]} stayOpen >
                            {data?.map((res, index) => {
                                return (
                                    <>
                                        <AccordionItem key={index}>
                                            <AccordionHeader targetId={index.toString()}>
                                                <div className='w-100'>
                                                    {moment(res?.appointmentDateTime).utc()?._d > moment().utc()?._d ? <></> : <>
                                                        <div className='d-flex justify-content-end'>
                                                            <div className='text-danger f-14 border border-danger rounded-pill px-2 '>Request Expired</div>
                                                        </div>
                                                    </>}
                                                    <div className='d-flex'>
                                                        <img src={res?.userDetails?.profile?.fileUrl || '/images/profile.png'} alt="" className="profile-image2" />
                                                        <div className='ms-2'>
                                                            <div className='fs-5 fw-500 text-black'>{res?.userDetails?.firstname}</div>
                                                            <div className='text-gray f-14 mt-2'>Age range :- {res?.userDetails?.age_range || '--'}</div>
                                                            <div className='text-gray f-14 mt-2'>{moment(res?.appointmentDateTime).utc().format("ddd, DD MMM | hh:mm A")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId={index.toString()}>
                                                <div className='d-flex'>
                                                    <div className="model-placeholder2">3D Model</div>
                                                    <div className='ms-2'>
                                                        <div className='fs-5 fw-500'>Assessment</div>
                                                        <div className='f-14 mt-2 word-wrap'><b>Symptoms</b> :- {res?.diagnoses?.map(item => item?.content)?.join(", ")}</div>
                                                        <div className='f-14 mt-1'>Patient probably has</div>
                                                        <div className='text-gray f-14 mt-1 word-wrap' >{getDesease(res?.diagnoses?.map(item => item?.content))?.join(", ")}</div>
                                                    </div>
                                                </div>
                                                {moment(res?.appointmentDateTime).utc()?._d > moment().utc()?._d ? <>
                                                    <hr />
                                                    <div className='row justify-content-center'>
                                                        <div className='col-lg-2 col-md-3 col-sm-6 col-6'><button className='btn rounded-pill fw-500 f-14 border w-100' onClick={() => openDialog('cancel', res?._id)}>Cancel Request</button></div>
                                                        <div className='col-lg-2 col-md-3 col-sm-6 col-6'><button className='btn rounded-pill fw-500 f-14 btn-primary w-100' onClick={() => openDialog('confirmed', res?._id)}>Confirm</button></div>
                                                    </div>
                                                </> : <></>}
                                            </AccordionBody>
                                        </AccordionItem>
                                    </>
                                )
                            })}
                        </UncontrolledAccordion>
                    </div>
                </>}
            </div>
        </>
    )
}
