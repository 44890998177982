import React, { useEffect, useRef, useState } from 'react'
import { json, Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CgProfile } from "react-icons/cg";
import { FaStar } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { BiLogOut } from "react-icons/bi";
import { jwtDecode } from 'jwt-decode';
import { MdOutlineWatchLater } from "react-icons/md";
import { Button, Modal, ModalBody, Popover, PopoverBody } from 'reactstrap';
import { CiCircleInfo } from "react-icons/ci";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";

export default function Header() {
    const userDetails = JSON.parse(localStorage.getItem('UserDetail'));
    const navigate = useNavigate();
    const targetRef = useRef(null);
    const popoverRef = useRef(null);
    const [open, setOpen] = useState(false)
    const [openNav, setOpenNav] = useState(true)
    const [role, setRole] = useState(true)
    const [type, setType] = useState('')
    const [popoverOpen, setPopoverOpen] = useState(false);

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    useEffect(() => {
        if (userDetails?.token) {
            let token = userDetails?.token || ''
            let decoded = {}
            if (token) {
                decoded = jwtDecode(token);
            }
            setRole(decoded?.role)
        } else {
            setRole('')
        }

    }, [logout, userDetails])


    function logout() {
        localStorage.clear()
        setOpen(false)
        if (type == 'professional') {
            navigate('/professional-login')
        } else {
            navigate('/login')
        }
    }

    // Close popover when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !targetRef.current.contains(event.target)
            ) {
                setPopoverOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Modal isOpen={open} centered>
                <ModalBody>
                    <div className='py-3 mt-2'>
                        <div className='d-flex justify-content-center'><CiCircleInfo className='text-danger' size={50} /></div>
                        <div className='text-center fw-500'>Are you sure you want to log out?</div>
                    </div>
                    <div className='d-flex justify-content-end gap-3 mt-2'>
                        <button className='btn btn-sm border fw-500' onClick={() => setOpen(false)}>Cancel</button>
                        <button className='btn btn-sm btn-primary border fw-500' onClick={logout}>Logout</button>
                    </div>
                </ModalBody>

            </Modal>
            <div className='bg-white' style={{ overflowX: 'hidden', boxShadow: '0px 0px 4px 0px', position: 'sticky', top: '0px', zIndex: '99' }}>
                {/* <header className='px-2 py-3'>
                <div className='row'>
                    <div className='order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6'>
                        <a className="navbar-brand p-0" href="http://eyemastr.com/">
                            <img src="/Img/headerLogo.png" alt="Logo" className='w-100' />
                        </a>
                    </div>
                    <div className='order-xl-3 order-lg-3 order-md-3 order-sm-3 order-3 col-xl-4 col-lg-8 col-md-8 col-sm-7 col-12 mt-xl-0 mt-lg-3 mt-sm-3 mt-3'>
                        <div className="div_input_search w-100" >
                            <input className="form-control me-2" type="search" placeholder="What are you looking for?" aria-label="Search" />
                            <i className="fa fa-search search_icon"></i>
                        </div>
                    </div>
                    <div className='order-xl-3 order-lg-2 order-md-2 order-sm-2 order-2 col-xl-5 col-lg-9 col-md-9 col-sm-9 col-12  mt-sm-0 mt-3'>
                        <div className="d-flex align-items-center justify-content-end">
                            <NavLink to="#" className="nav-link text-dark p-md-2 p-sm-0 p-0 pe-md-3 pe-sm-2 pe-3 d-md-block d-sm-none d-none">Track Order</NavLink>
                            <NavLink to="#" className="nav-link text-dark p-md-2 p-sm-0 p-0 pe-md-3 pe-sm-2 pe-3">Sign In & Sign Up</NavLink>
                            {pathname == '/' ? userID?.id ? <CgProfile className="pointer" size={20} onClick={() => { navigate("/profileView") }} />
                                : <button className='btn btn-primary' onClick={() => { navigate("/signup") }} >
                                    Register As Eyemast<FaStar color='#FFD700' />r
                                </button> : ''}
                        </div>
                    </div>
                    <div className='order-xl-4 order-lg-4 order-md-4 order-sm-4 order-4 col-xl-2 col-lg-4 col-md-4 col-sm-5 col-12 mt-xl-0 mt-lg-3 mt-sm-3 mt-3'>
                        <div className="d-flex align-items-center justify-content-end">
                            <NavLink to="#" className="nav-link text-dark p-md-2 p-sm-0 p-0 ps-md-3 ps-sm-2 ps-3 d-md-none d-sm-block d-block">Track Order</NavLink>
                            <NavLink to="#" className="nav-link text-dark p-2"><FaHeart/></NavLink><span className="ms-md-2 ms-sm-1 ms-1">Wishlist</span>
                            <NavLink to="#" className="nav-link text-dark p-2"><FaCartShopping /></NavLink><span className="ms-md-2 ms-sm-1 ms-1">Cart</span>
                        </div>
                    </div>
                </div>
            </header> */}
                <header className='px-2 py-3 container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-3 col-md-4 col-6'>
                            <a className="navbar-brand p-0" href="http://eyemastr.com/">
                                <img src="/Img/headerLogo.png" alt="Logo" className='w-100' />
                            </a>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 d-flex justify-content-end gap-3 align-items-center'>
                            {role == 'user' ? <div className='d-flex gap-1 align-items-center text-success pointer' onClick={() => navigate('/my-appointments')}><MdOutlineWatchLater className='text-success' />My Appointments</div> : ''}
                            {role == 'professional' ? <div className='headerDropDownContainer' ref={popoverRef}>
                                <Button
                                    innerRef={targetRef}
                                    onClick={togglePopover}
                                    type="button"
                                   
                                >
                                     <BsThreeDotsVertical size={20}/>
                                </Button>
                                <Popover
                                    flip
                                    placement="bottom"
                                    target={targetRef.current}
                                    isOpen={popoverOpen}
                                    toggle={togglePopover}
                                >
                                    <PopoverBody>
                                        <div>
                                        <div className='d-flex gap-2 align-items-center pointer py-2 fw-500' onClick={() => navigate('/set-availabillity')}><MdOutlineWatchLater size={20} /> Availability</div>
                                        <div className='d-flex gap-2 align-items-center pointer py-2 fw-500' onClick={() => navigate('/appointments')}><FaListUl size={16} /> Appointments</div>
                                        </div>
                                    </PopoverBody>
                                </Popover>
                            </div> : ''}
                            {role == 'professional' ? <BiLogOut size={24}  className='pointer mt-1' onClick={() => {
                                setOpen(true)
                                setType('professional')
                            }} /> : ''}
                            {role == 'user' ? <BiLogOut size={24} className='pointer' onClick={() => {
                                setOpen(true)
                                setType('user')
                            }} /> : ''}

                        </div>
                    </div>
                </header>
                {/* <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <button class="navbar-toggler ms-auto" type="button" onClick={() => setOpenNav(!openNav)} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class={`${openNav ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">New Arrivals</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Eyeglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Computer Eyeglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Sunglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Prescription Sunglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Kids Eyeglasses</NavLink>
                            </li>
                        </ul>
                        <div className="d_flex_icon">
                            <button className="btn btn-sm btn-outline-success mx-1">TRY ON</button>
                            <button className="btn btn-sm btn-outline-primary mx-1">BLU</button>
                            <button className="btn btn-sm btn-outline-dark mx-1">GOLD</button>
                        </div>
                    </div>
                </div>
            </nav> */}
            </div>
        </>
    )
}
