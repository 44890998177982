import React, { useEffect, useState } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, ModalHeader, UncontrolledAccordion } from 'reactstrap';
import { getCompletedAppointments, getRequestedAppointments } from '../../Services/AllApi';
import { MdOutlineWatchLater } from "react-icons/md";
import { symptomToDiseaseMap } from '../../Assets/constants';
import { BiFilterAlt } from "react-icons/bi";
import moment from 'moment';

const numberOfDays = 60;


export default function Completed({ setLoader }) {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [allData, setAllData] = useState([])
    const [isFilter, setIsFilter] = useState(false)
    const [selectedDate, setSelectedDate] = useState(moment().utc().format('yyyy-MM-DD'));

    const filterData = async (e) => {
        e.preventDefault()
        setOpen(false)
        setIsFilter(true)
        let filterData = allData?.filter(res => moment(res?.appointmentDateTime).utc().format('yyyy-MM-DD') == selectedDate)
        setData(filterData)
    };


    async function getAllData() {
        try {
            setLoader(true)
            let res = await getCompletedAppointments()
            let response = res?.data || []
            setAllData(response)
            setData(response)
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }



    useEffect(() => {
        getAllData();
    }, []);


    function getDiseasesFromSymptoms(symptoms) {
        const diseases = new Set();

        symptoms.forEach(symptom => {
            if (symptomToDiseaseMap[symptom]) {
                symptomToDiseaseMap[symptom].forEach(disease => diseases.add(disease));
            }
        });

        return Array.from(diseases);
    }


    function getDesease(data) {
        let getData = data

        if (!Array.isArray(getData)) {
            console.error("getData is not an array");
            getData = [];
        }
        let arr = getDiseasesFromSymptoms(getData)
        return arr

    }

    function clearFilter() {
        setData(allData)
        setIsFilter(false)
        setSelectedDate(moment().utc().format('yyyy-MM-DD'))
    }

    return (
        <>
            <Modal isOpen={open} centered>
                <ModalHeader>Filter by date</ModalHeader>
                <ModalBody>
                    <form onSubmit={filterData}>
                        <div className='pb-3'>
                            <label className='fw-500'>Select Date</label>
                            <input type='date' className='form-control' required placeholder='Select Date' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                        </div>
                        <div className='d-flex justify-content-end gap-3 mt-2'>
                            <button className='btn btn-sm border fw-500' type='button' onClick={() => setOpen(false)}>Cancel</button>
                            <button className='btn btn-sm btn-primary border fw-500' type='submit'>Submit</button>
                        </div>
                    </form>
                </ModalBody>

            </Modal>
            {isFilter ?
                <div className='d-flex justify-content-end mb-3'>
                    <button className='btn btn-sm f-14 fw-500 border d-flex align-items-center gap-1' onClick={clearFilter}>Clear Filter</button>
                </div> : ''}
            <div className=''>
                {data?.length == 0 ? <>
                    <div className='noDataScreen mt-3'>
                        <div>
                            <div className='w-100 d-flex justify-content-center'><MdOutlineWatchLater size={60} className='text-success' /></div>
                            <div className='text-center fw-500 fs-5 mt-2'>No Completed Appointments</div>
                            <div className='text-center f-14 mt-2 text-gray'>No appointment completed are available for this day. Please check another day.</div>
                        </div>
                    </div>

                </> : <>
                    <div style={{ minHeight: '40vh' }}>
                    {!isFilter ?
                        <div className='d-flex justify-content-end mb-3'>
                            <button className='btn btn-sm f-14 fw-500 border d-flex align-items-center gap-1' onClick={() => setOpen(true)}><BiFilterAlt /> Filter</button>
                        </div> : ''}
                    <UncontrolledAccordion defaultOpen={[]} stayOpen >
                        {data?.map((res, index) => {
                            return (
                                <>
                                    <AccordionItem key={index}>
                                        <AccordionHeader targetId={index.toString()}>
                                            <div>
                                                <div className='text-success f-14'>Appointment Completed</div>
                                                <div className='d-flex mt-3'>
                                                    <img src={res?.userDetails?.profile?.fileUrl || '/images/profile.png'} alt="" className="profile-image2" />
                                                    <div className='ms-2'>
                                                        <div className='fs-5 fw-500 text-black'>{res?.userDetails?.firstname}</div>
                                                        <div className='text-gray f-14 mt-2'>Age range :- {res?.userDetails?.age_range || '--'}</div>
                                                        <div className='text-gray f-14 mt-2'>{moment(res?.appointmentDateTime).utc().format("ddd, DD MMM | hh:mm A")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionHeader>
                                        <AccordionBody accordionId={index.toString()}>
                                            <div className='d-flex'>
                                                <div className="model-placeholder2">3D Model</div>
                                                <div className='ms-2'>
                                                    <div className='fs-5 fw-500'>Assessment</div>
                                                    <div className='f-14 mt-2 word-wrap'><b>Symptoms</b> :- {res?.diagnoses?.map(item => item?.content)?.join(", ")}</div>
                                                    <div className='f-14 mt-1'>Patient probably has</div>
                                                    <div className='text-gray f-14 mt-1 word-wrap' >{getDesease(res?.diagnoses?.map(item => item?.content))?.join(", ")}</div>
                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </AccordionItem>
                                </>
                            )
                        })}
                    </UncontrolledAccordion>
                    </div>
                </>}
            </div>
        </>
    )
}
