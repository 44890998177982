import React, { useState, useEffect } from "react";
import "./SlotPicker.css";
import moment from "moment";
import "./Professional.css";
import {
  getDoctorsDetailById,
  getAvailabilityData,
  appAppoinmentByuser,
} from "../../Services/AllApi";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { IoLocationOutline } from "react-icons/io5";
import { Modal, ModalBody } from "reactstrap";
import { IoCloseSharp } from "react-icons/io5";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { baseUrl } from "../../Services/Endpoint";
import CryptoJS from 'crypto-js';
import { IoIosArrowBack } from "react-icons/io";
import toast from "react-hot-toast";
import RatingStars from "../../Components/RatingStars/RatingStars";


// const stripePromise = loadStripe(
//   "pk_live_51Q638VL06v98rXHkK6B7MpLNswfPfqw1iUUNsR53eFaj8ENLR1xUixMYmRtnjZN52FT8Wr0o91CQ2baVu5WA8dv000ZBEE3jzZ"
// );

const stripePromise = loadStripe(
  "pk_test_51Q638VL06v98rXHkhl5qtB6ItFHwGhu0ryjlRknqn1DvjgdvJeGbpejc6SiEcWPxaRHpmjECKYjKwcBrTuDE2HDg001UcC2ufb"
);


export default function SlotPicker() {
  const navigation = useNavigate();
  const params = useParams();
  const [getDetailsById, setGetDetailsById] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getSlots, setgetSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState("Today");
  const [dates, setDates] = useState([]);
  const [selectSlots, setSelectSlots] = useState("");
  const [selectDate, setSelectDate] = useState({
    name: "Today",
    date: new Date(),
  });

  const [openModal, setOpenModal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const options = {
    clientSecret: clientSecret,
  };

  const getDataById = async () => {
    setLoader(true);
    try {
      let res = await getDoctorsDetailById(params?.id);
      setGetDetailsById(res?.data?.user);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const getTodayWithTime = (time12h) => {
    // Get today's date
    const today = new Date();
    
    // Extract the hours and minutes from the time string
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
  
    // Convert the 12-hour format to 24-hour format
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0; // 12 AM is 00 in 24-hour format
    }
  
    // Set the hours and minutes on today's date object
    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(0); // Reset seconds to 0
    today.setMilliseconds(0); // Reset milliseconds to 0
  
    return today;
  };

  const getAllAvailabilityData = async (date) => {
    const payloadData = {
      professionalId: params.id,
      date: moment(date).format("YYYY/MM/DD"),
    };
    setLoader(true);
    try {
      const res = await getAvailabilityData(payloadData);
      let data = res?.data?.map((res, index) => {
        if (moment(date).format("YYYY/MM/DD") == moment().format("YYYY/MM/DD")) {
          if(moment()._d > moment(getTodayWithTime(res?.startTime))._d){
            return {
              ...res,
              isBooked:true
            }
          }else{
            return {
              ...res
            }
          }


        } else {
          return res
        }
      })
      console.log(data);
      setgetSlots(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  let getData = JSON.parse(sessionStorage.getItem("selectedConcerns"));

  const appointmentBook = async () => {
    console.log(createDateWithTime(selectDate.date, selectSlots))
    console.log(moment("2024-11-27T09:18:00.000Z").format('hh:mm A'))
    // sessionStorage.setItem('test',JSON.stringify(createDateWithTime(selectDate.date, selectSlots)))
    // console.log(JSON?.parse(localStorage?.getItem("UserDetail"))?.token)
    if (selectSlots) {
      if (JSON?.parse(localStorage?.getItem("UserDetail"))?.token) {
        setOpenModal(true);
      } else {
        navigation(`/login?type=professional-detail&id=${params?.id}`);
      }
    } else {
      toast.error("Please select a time slot before proceeding to book appointment.")
    }
  }

  const numberOfDays = 60;

  const generateDates = () => {
    const today = new Date();
    const dates = [];
    for (let i = 0; i < numberOfDays; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      const dayName =
        i === 0
          ? "Today"
          : i === 1
            ? "Tomorrow"
            : date.toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
            });
      dates.push({ name: dayName, date });
    }
    return dates;
  };

  useEffect(() => {
    getDataById();
    getAllAvailabilityData(new Date());
    setDates(generateDates());
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date.name);
    setSelectDate(date);
    getAllAvailabilityData(date.date);
  };

  function createDateWithTime(dateString, timeString) {
    // Parse the ISO date string into a moment object
    const dateTime = moment(dateString).utc();
  
    // Parse the time string and override the time in the moment object
    const time = moment(timeString, 'hh:mm A');
    dateTime.set({
      hour: time.hours(),
      minute: time.minutes(),
      second: 0,
      millisecond: 0
    });
  
    // Return as a JavaScript Date object
    return dateTime.toDate();
  }

  useEffect(() => {
    getPaymentIntent();
  }, []);

  async function getPaymentIntent() {
    try {
      let payload = {
        amount: 2000,
        type: 'aed' // inr or aed
      }
      let res = await axios.post(`${baseUrl}user/create-payment-intent`, payload);
      let key = res?.data?.clientSecret;
      setClientSecret(key);
    } catch (err) {
      console.log(err);
    }
  }





  return (
    <>
      <Modal isOpen={openModal} size="md" centered>
        <ModalBody>
          <div className="d-flex justify-content-end mb-3">
            <IoCloseSharp
              size={18}
              className="pointer"
              onClick={() => setOpenModal(false)}
            />
          </div>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm createDateWithTime={createDateWithTime} selectDate={selectDate} selectSlots={selectSlots} getData={getData} navigation={navigation} drData={getDetailsById} />
            </Elements>
          )}
        </ModalBody>
      </Modal>
      <div className="container mt-3">
        <Loader open={loader} />
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn border p-1 px-2"
            onClick={() => navigation("/near-me-eyemaster")}
          >
            <IoIosArrowBack size={18} />
          </button>
          <div className="fw-500 f-18 d-md-none d-block">Book Appointment</div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>

        <div className="main_container_book_now my-4">
          <div className="first_div_container_book_now">
            <div className="profile-container">
              <div className="profile-content">
                <div className="profile-picture d-flex align-items-center">
                  <img
                    src={getDetailsById?.profile?.fileUrl}
                    alt="Doctor Avatar"
                  />
                  <div className="doctor-profile-detail">
                    <div className="doctor-name">
                      {getDetailsById?.prefix} {getDetailsById?.firstname}
                    </div>
                    <div className="doctor-details">
                      {getDetailsById?.profession} |{" "}
                      {getDetailsById?.qualification}<br />
                      {getDetailsById?.speciality?.map((res, index) => " " + `${index === 0 ? '' : '|'}` + " " + res)}
                    </div>
                    <div className="experience-text">
                      {getDetailsById?.yearsOfExperience} years of experience
                      overall
                    </div>
                    <RatingStars averageRating={getDetailsById?.use_admin_rating ? getDetailsById?.admin_rating?.avg : getDetailsById?.averageRating} ratingCount={getDetailsById?.use_admin_rating ? getDetailsById?.admin_rating?.count : getDetailsById?.totalRatings} />

                  </div>
                </div>

                <section className="working-time"></section>
                <section className="location mt-sm-5 mt-3">
                  <div className="fs-5">Location</div>
                  <div>
                    <IoLocationOutline />
                    <span className="ps-1 experience-text">
                      {getDetailsById?.company}, {getDetailsById?.city},{" "}
                      {getDetailsById?.state}, {getDetailsById?.country}
                    </span>
                  </div>
                </section>
                <section className="mt-3">
                  <span><b>Booking charges</b></span> - {getDetailsById?.country === "India" ? <span>INR 100</span> : <span>AED 20</span>}
                </section>
              </div>
            </div>
          </div>
          <div className="second_div_container_book_now">
            <div className="slot-selector">
              <div className="date-slider">
                {dates.map((date) => (
                  <div
                    key={date.name}
                    className={`tab ${selectedDate === date.name ? "active" : ""
                      }`}
                    onClick={() => handleDateClick(date)}
                  >
                    <p className="tab-title">{date.name}</p>
                  </div>
                ))}
              </div>
              <div className="slot-buttons">
                {getSlots.length > 0 ? (
                  getSlots?.map((slot, index) => (
                    <React.Fragment key={index}>
                      {slot.isBooked ? (
                        <button className="slot_disable rounded-pill f-14 fw-500" disabled>
                          {slot?.startTime + ' - ' + slot?.endTime}
                        </button>
                      ) : (
                        <button
                          className={`slot rounded-pill f-14 fw-500 ${selectSlots === slot?.startTime ? "selected" : ""
                            }`}
                          onClick={() => {
                             setSelectSlots(slot?.startTime)
                             console.log(createDateWithTime(selectDate.date,slot?.startTime))
                             }}
                        >
                          {selectSlots === slot?.startTime
                            ? slot?.startTime + ' - ' + slot?.endTime
                            : slot?.startTime + ' - ' + slot?.endTime}
                        </button>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <p className="slot_not_available">
                    No slots are currently available. Please choose another
                    date.
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-primary" onClick={appointmentBook} >Book Appointment</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CheckoutForm = ({ createDateWithTime, selectDate, selectSlots, getData, navigation, drData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const params = useParams();



  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      professionalId: params.id,
      appointmentDateTime: createDateWithTime(selectDate.date, selectSlots),
      serviceType: "offline",
      diagnoses: getData.map((e) => {
        return {
          content: e,
        };
      }),
    };
    sessionStorage.setItem('appointmentDetails', JSON.stringify(payload))
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }
    setLoading(true);


    const data = [payload];

    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

    // Decrypt
    // const bytes = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
    // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // return
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://professional.eyemastr.com/appointment-success?id=${params.id}?encrypt=${ciphertext}`,
        // return_url: `http://localhost:3000/appointment-success?id=${params.id}&encrypt=${ciphertext}`

      },
    });

    // The payment is successful
    if (JSON?.parse(localStorage?.getItem("UserDetail"))?.token) {

      // const response = await appAppoinmentByuser(payload);

    } else {
      navigation("/login");
    }

    if (result.error) {
      // Show error to your customer
      console.log(result.error.message);
    } else {

      console.log("Payment successful!");
    }
  };


  function checkCountry(key) {
    switch (key) {
      case "India":
        return "INR"
        break;
      case 'United Arab Emirates':
        return "AE"
        break;

      default:
        return "AE"
        break;
    }
  }

  const paymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          country: checkCountry(drData?.country), // ISO code for UAE
        },
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      <button
        className="btn btn-primary mt-3 w-100"
        disabled={!stripe || loading}
      >
        Submit
      </button>
    </form>
  );
};
