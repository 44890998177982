export const symptomToDiseaseMap = {
    'Headache': ['Astigmatism', 'Convergence Insufficiency'],
    'Blurred vision in distance': ['Myopia', 'Astigmatism'],
    'Blurred vision all time': ['Astigmatism'],
    'Blurred vision in near work': ['Hypermetrophia', 'Presbyopia'],
    'Tiredness during computer work': ['Hypermetrophia', 'Astigmatism'],
    'Frequent watering in eyes': ['Astigmatism', 'Allergy'],
    'Frequent redness in eyes': ['Astigmatism', 'Allergy'],
    'Feeling dryness in eyes': ['Blinking insufficiency', 'Computer vision syndrome'],
    'Squeezing eyes while watching TV': ['Astigmatism', 'Myopia'],
    'Difficulty in Sunlight': ['Photophobia due to Astigmatism'],
};