import React from "react";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";

export default function RatingStars({ averageRating, ratingCount }) {
    const fullStars = Math.floor(averageRating);
    const hasHalfStar = averageRating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  
    const renderStars = () => {
      const stars = [];
  
  
      for (let i = 0; i < fullStars; i++) {
        stars.push(<FaStar color="#FFD700" size={14} key={`full-${i}`} />);
      }
  
  
      if (hasHalfStar) {
        stars.push(<FaRegStarHalfStroke color="#FFD700" size={14} key="half" />);
      }
  
  
      for (let i = 0; i < emptyStars; i++) {
        stars.push(<FaRegStar  size={14} key={`empty-${i}`} />);
      }
  
      return stars;
    };
  
    return (
      <div className="d-flex align-items-center gap-2">
        <div className="rating-stars">{renderStars()}</div>
        <div className="rating-count f-14 pt-1">({ratingCount} ratings)</div>
      </div>
    );
  };
  