import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

const libraries = ['places']

const MapComponent = ({ selectedPosition, setSelectedPosition }) => {
    const [loading, setLoading] = useState(false);






    return (
        <>
            {/* <SearchBox setSelectedPosition={setSelectedPosition} setinnerData={setinnerData} /> */}
            {loading && <div>Loading map...</div>}
            {selectedPosition?.lat && selectedPosition?.lng ? <Map latitude={selectedPosition?.lat} longitude={selectedPosition?.lng} /> : ''}
        </>
    );
};

export default MapComponent;


const containerStyle = {
    width: '100%',
    height: '400px'
};

function Map({ latitude, longitude }) {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCKodpnQoon5aC5csg7IXgFKD2gETOl0HA',
    });

    const center = {
        lat: latitude,
        lng: longitude,
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
        >
            <Marker position={center} />
        </GoogleMap>
    );
}
