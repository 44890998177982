import React, { useEffect, useState } from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { getProfileData, updatePaymentstatus,appAppoinmentByuser } from '../../Services/AllApi';
import CryptoJS from 'crypto-js';


export default function PaymentSuccess() {
    const navigate = useNavigate()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [loader, setLoader] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState(true)
    const encrypt = decodeURIComponent(urlParams.get("encrypt"));
    useEffect(() => {
        const id = urlParams.get('id');
        // const encrypt = urlParams.get("encrypt")
        // const encrypt = decodeURIComponent(urlParams.get("encrypt"));
        const paymentIntent = urlParams.get('payment_intent');
        const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');
        const redirectStatus = urlParams.get('redirect_status');
        console.log(id)
        console.log(paymentIntent)
        console.log(paymentIntentClientSecret)
        console.log(redirectStatus)
        console.log(encrypt)
 
        
      

       

        if (redirectStatus) {
            if (redirectStatus == 'succeeded') {
                setPaymentStatus(true)
                addDetails(true, paymentIntent, 'succeeded', id)
            } else {
                setPaymentStatus(false)
                addDetails(false, paymentIntent, 'failed', id)
            }

        } else {

        }

    }, [])

    const appointment = async(id) =>{
    
            const bytes = CryptoJS.AES.decrypt(encrypt, 'secret key 123');
            const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
            console.log("Decrypted text:", JSON.parse(decryptedText));
        
            const decryptedData = JSON.parse(decryptedText)[0];
           
            decryptedData.paymentId = id
            console.log(id)
            try{
                let response = await appAppoinmentByuser(decryptedData)
                console.log(response)
            }catch(err){
                console.log(err)
            }
    }


    async function addDetails(status, tId, status2, userId) {
        let userData = await getProfileData(userId)
        let data = userData?.data?.user
        try {
            let payload = {
              isPaymentComplete: status,
              paymentAmount: data?.country == "India" ? 100.0 : 25.0,
              paymentCurrency: data?.country == "India" ? "INR" : "AED",
              paymentDate: new Date(),
              transactionId: tId,
              paymentMethod: "strip",
              paymentStatus: status2,
              paymentFor:"professional registration",
            };
            console.log(payload)
            let res = await updatePaymentstatus(userId,payload)
            console.log(res)
            // await appointment(res?.data?.paymentDetails?._id)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }




    return (
        <>
            <Loader open={loader} />
            <div className='w-100 py-5 my-md-5 my-sm-5 my-0 ' style={{ overflowX: 'hidden' }}>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='w-100 py-5'>
                            <div className='d-flex justify-content-center pb-2'>
                                {paymentStatus ? <FaRegCheckCircle size={45} color='#007bff' className='text-center' /> : <IoMdInformationCircleOutline size={45} className='text-danger text-center' />}
                            </div>
                            <h3 className='text-center'>{paymentStatus ? 'Payment successful' : 'Payment unsuccessful'}</h3>
                            <div className='text-center text-gray mt-1'>{paymentStatus ? 'Thank you for joining as Eyemast⭐️r.' : 'Your payment was unsuccessful.'}</div>
                            <div className='text-center text-gray'>{paymentStatus ? 'Your Expertise will be visible now  to customers & Patients!!' : 'Please check your details and try again.'}</div>
                            <div className='d-flex justify-content-center mt-3'>
                                <a className='btn btn-sm btn-primary'href='https://eyemastr.com/'>Back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
