import axios from "axios";

import { API, baseUrl } from "./Endpoint";

export const professionalSignup = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.professionalSignup}`,
        payload
    );
};

export const professionalLogin = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.professionalLogin}`,
        payload
    );
};

export const profileData = async (payload, config) => {
    return await axios.put(
        `${baseUrl}${API.Allapi.profileData}`,
        payload, config
    );
};

export const uploadImg = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.uploadImg}`,
        payload
    );
};


export const deleteImg = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.deleteImg}`,
        payload
    );
};




export const addOTP = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.addOTP}`,
        payload
    );
}



export const verifyOTP = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.verifyOTP}`,
        payload
    );
}

export async function getProfileData(id) {
    return axios.get(`${baseUrl}${API.Allapi.userData}${id}`)
}



export const professionalView = async (config) => {
    return await axios.get(
        `${baseUrl}${API.Allapi.professionalView}`,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    );
}
export const updatePaymentstatus = async (id, payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.paymentData}${id}`,
        payload
    );
}

export const getAllDoctorsList = async () => {
    return await axios.get(
        `${baseUrl}${API.Allapi.doctorListing}`
    )
}

export const getDoctorsDetailById = async (id) => {
    return await axios.get(
        `${baseUrl}${API.Allapi.doctorsDetail}${id}`
    )
}

export const getAvailabilityData = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.getavailability}`, payload,

    )
}

export const appAppoinmentByuser = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.addAppointment}`, payload,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}

export const changeAppointmentStatus = async (payload) => {
    return await axios.put(
        `${baseUrl}${API.Allapi.updateAppointmentStatus}`, payload,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}


export const rescheduleAppointment = async (payload) => {
    return await axios.put(
        `${baseUrl}${API.Allapi.rescheduleAppointment}`, payload,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}


export const addUserOtpSignUp = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.AddOtpUser}`, payload
    )
}
export const userLogin = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.loginUser}`, payload
    )
}
export const checkUserExistOrNot = async (payload) => {
    return await axios.post(
        `${baseUrl}${API.Allapi.checkUserExist}`, payload
    )
}

export const userVerifyOtp = async (payload) => {
    return axios.post(
        `${baseUrl}${API.Allapi.verifyOtpUser}`, payload
    )
}

export const completeProfileOfUser = async (payload,config) => {
    return axios.put(
        `${baseUrl}${API.Allapi.CompleteUserProfile}`, payload, config
    )
}

export const addAvailabillity = async (payload) => {
    return axios.post(
        `${baseUrl}${API.Allapi.setAvailabillity}`, payload,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}

export const getAvailabillity = async () => {
    return axios.get(
        `${baseUrl}${API.Allapi.getAvailabilityData}`,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}

export const getAllApprovedProfessionals = async () => {
    return axios.get(`${baseUrl}${API.Allapi.getAprrovedProfessional}`)
}



export const getRequestedAppointments = async () => {
    return axios.get(`${baseUrl}${API.Allapi.requestedAppointmemnts}`,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}
export const getUpcomingAppointments = async () => {
    return axios.get(`${baseUrl}${API.Allapi.upcomingAppointmemnts}`,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}
export const getCompletedAppointments = async () => {
    return axios.get(`${baseUrl}${API.Allapi.completedAppointmemnts}`,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}

export const getCanceledAppointments = async () => {
    return axios.get(`${baseUrl}${API.Allapi.canceledAppointments}`,
        {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("UserDetail")).token}`
            }
        }
    )
}