import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';

const SearchBox = ({ setSelectedPosition,setinnerData }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete();

    const [loading, setLoading] = useState(false);
  

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            setLoading(true);
            const results = await getGeocode({ address });
            setinnerData(results[0])
            const { lat, lng } = await getLatLng(results[0]);
            setSelectedPosition({ lat, lng });
        } catch (error) {
            console.error('Error: ', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedSetValue = debounce((value) => setValue(value), 100);

    const handleChange = (e) => {
        debouncedSetValue(e.target.value);
    };

    return (
        <div className=''>
            <input
                value={value}
                className='form-control'
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready || loading}
                placeholder={loading ? "Loading..." : "Search for a location"}
            />
            {status === 'OK' && (
                <ul className='list-style'>
                    {data?.length === 0 && <li>No results found</li>}
                    {data?.map(({ place_id, description }, index) => (
                        <li 
                            key={place_id} 
                            onClick={() => handleSelect(description)}
                            tabIndex={index}
                        >
                            {description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBox;
