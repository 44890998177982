import React, { useEffect, useState } from "react";
import "./ShareYourConcern.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import { symptomToDiseaseMap } from "../../Assets/constants";

export default function Symptoms() {
  const navigation = useNavigate();
  const [concernArr,setConcernArr] = useState([])
  let getData = JSON.parse(sessionStorage.getItem("selectedConcerns"));

  useEffect(()=>{
    if (!Array.isArray(getData)) {
      console.error("getData is not an array");
      getData = []; // Fallback to an empty array if needed
    }
    let arr = getDiseasesFromSymptoms(getData)
    setConcernArr(arr)
  },[])


  
  
  function getDiseasesFromSymptoms(symptoms) {
    const diseases = new Set();
  
    symptoms.forEach(symptom => {
      if (symptomToDiseaseMap[symptom]) {
        symptomToDiseaseMap[symptom].forEach(disease => diseases.add(disease));
      }
    });
  
    return Array.from(diseases);
  }

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn border p-1 px-2"
          onClick={() => {
            navigation("/Share-your-concerns");
          }}
        >
          <IoIosArrowBack size={18} />
        </button>
        <div className="fw-500 f-18">Initial Assessment</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div className="app-container">
        <div className="d-flex justify-content-center">
          <h5 className="symptoms_heading">Symptoms</h5>
        </div>
        <div className="symptom-buttons-symptoms mt-3">
          {getData?.map((e, index) => (
            <button key={index} className="symptom-button-btn-symptoms">
              {e}
            </button>
          ))}
        </div>
        <div className="model-display">
          <div className="model-placeholder">3D Model</div>
        </div>
        <div className="mt-4">
          <div className="your_probably d-flex justify-content-center">
            You probably have
          </div>
          <div className="mt-3 converence_insu text-center">
            {concernArr?.join(', ')}
          </div>
        </div>
        <div className="row justify-content-center pb-5 w-100 ms-1">
          <div className="col-md-3">
            <button
              className="submit-button fw-500 w-100"
              onClick={() => {
                navigation("/near-me-eyemaster");
              }}
            >
              EyeMast<IoMdStar />r near me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
