import React, { useState, useEffect } from "react";
import { City, Country, State } from "country-state-city";
import { completeProfileOfUser, deleteImg, professionalView, uploadImg } from "../../Services/AllApi";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import Loader from "../../Components/Loader/Loader";


export default function UserProfile() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [img, setImg] = useState("");
    const [imgData,setImgData] = useState('')
    const [edit, setEdit] = useState(true)
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [backupData, setbackupData] = useState({})
    const [profileImage, setProfileImage] = useState(null);
    const [formData, setFormData] = useState({
        age_range: "",
        country: "",
        state: "",
        city: "",
        email: "",
        firstname: "",
        profile: {
            fileUrl: "",
            fileName: "",
        },
    });


    // Filter countries to show only India and UAE
    const countryOptions = Country.getAllCountries().filter(
        (e) => e.name === "India" || e.name === "United Arab Emirates"
    );

    // Load states when the country changes
    useEffect(() => {
        if (formData.country) {
            const states = State.getStatesOfCountry(formData.country);
            setStateList(states);
            setCityList([]); // Clear city list when country changes
        }
    }, [formData.country]);

    // Load cities when the state changes
    useEffect(() => {
        if (formData.state) {
            const cities = City.getCitiesOfState(formData.country, formData.state);
            setCityList(cities);
        }
    }, [formData.state]);

    const handleChangeUser = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCountryChange = (e) => {
        const countryIsoCode = e.target.value;
        setFormData((prev) => ({ ...prev, country: countryIsoCode, state: "", city: "" }));
    };

    const handleStateChange = (e) => {
        const stateIsoCode = e.target.value;
        setFormData((prev) => ({ ...prev, state: stateIsoCode, city: "" }));
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImg(file);
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
        }
    };

    const handleImage = async () => {
        if (img.name !== undefined) {
            const formData = new FormData();
            formData.append('upload', img);
            try {
                if(imgData?.fileName){
                    await deleteImg([imgData.fileName]);
                }
                const response = await uploadImg(formData);
                return response?.data?.data[0] || []
            } catch (error) {
                console.log(error);
            }
        } else {
            return { fileName: imgData?.fileName, fileUrl: imgData?.fileUrl }
        }
    }

    const submitCompleteProfile = async (e) => {
        e.preventDefault();
        setLoading(true)

        const imageURl = await handleImage();
        let payload = {
            age_range: formData.age_range,
            country: formData.country,
            state: formData.state,
            city: formData.city,
            firstname: formData.firstname,
            email: formData.email,
            profile: imageURl,
        }
       let token = JSON.parse(localStorage.getItem("UserDetail")).token
        try {
            let config = {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            await completeProfileOfUser(payload,config)
            setLoading(false)
            await getUserData()
            setEdit(true)
            setImg('')
            toast.success("Your Profile updated successfully.")

        } catch (err) {
            console.log(err)
            let error = err.response.data.message || 'Some error occupide'
            toast.error(error)
            setLoading(false)
        }
    }


    async function getUserData() {
        setLoader(true)
        try {
            let res = await professionalView()
            let data = res?.data?.user
            let fData = {
                firstname: data?.firstname,
                age_range: data?.age_range,
                email: data?.email,
                country: data?.country,
                state: data?.state,
                city: data?.city
            }
            setImgData(data?.profile)
            setbackupData(fData)
            setFormData(fData)

        } catch (err) {
            console.log(err)
            
        } finally {
            setLoader(false)
        }
    }
    useEffect(() => {
        getUserData()
    }, [])

    return (
        <div className="container pb-sm-5 pb-0 mb-2">
            <Loader open={loader} />
            <div className="d-flex justify-content-between mt-md-4 mt-3">
                <div className="fs-4 fw-500">User Profile</div>
                {edit ? <button className='btn btn-sm border fw-500 d-flex align-items-center gap-1' type='button' onClick={() => setEdit(false)}><FaRegEdit />Edit</button> : <button className='btn btn-sm fw-500 border' type='button' onClick={() => {
                    setEdit(true)
                    setFormData(backupData)
                }}>Cancel</button>}
            </div>
            <form onSubmit={submitCompleteProfile}>
                <div className="row mt-4">
                    <div className="col-12 ">
                        <div className="d-flex align-items-center flex-md-row flex-sm-row flex-column">
                            <div className="profilePreview">
                                {profileImage ? (
                                    <img src={profileImage} alt="Profile Preview" style={{ width: "auto", height: "100%" }} />
                                ) : (
                                    <img src="/images/profile.png" alt="Profile Preview" style={{ width: "auto", height: "100%" }} />
                                )}
                            </div>
                            <div className="ms-4 d-flex flex-column">
                                <label className=" mb-1">
                                    Profile Picture
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file pointer"
                                    name="profile"
                                    disabled={edit}
                                    onChange={handleFileInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4 col-12 mt-3">
                        <label className=" mb-1">
                            Name <span className="text-danger">*</span>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Enter your name"
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleChangeUser}
                            disabled={edit}
                            required
                        />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                        <label className=" mb-1">
                            Age range <span className="text-danger">*</span>
                        </label>
                        <select required value={formData.age_range} name="age_range" disabled={edit} className="form-select" onChange={handleChangeUser}>
                            <option value=''>Select Age Range</option>
                            <option value='Less than 10 years'>Less than 10 years</option>
                            <option value='10 - 20 years'>10 - 20 years</option>
                            <option value='20 - 30 years'>20 - 30 years</option>
                            <option value='30 - 40 years'>30 - 40 years</option>
                            <option value='40 - 50 years'>40 - 50 years</option>
                            <option value='50 - 60 years'>50 - 60 years</option>
                            <option value='60 above'>60 above</option>
                        </select>

                    </div>

                    <div className="col-md-4 col-12 mt-3">
                        <label className=" mb-1">
                            Email <span className="text-danger">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="email"
                            name="email"
                            required
                            disabled={edit}
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChangeUser}

                        />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                        <label className=" mb-1">
                            Country <span className="text-danger">*</span>
                        </label>
                        <select
                            className="form-select"
                            name="country"
                            value={formData.country}
                            onChange={handleCountryChange}
                            required
                            disabled={edit}
                        >
                            <option value="">Select Country</option>
                            {countryOptions.map((country) => (
                                <option key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                        <label className=" mb-1">
                            State <span className="text-danger">*</span>
                        </label>
                        <select
                            className="form-select"
                            name="state"
                            value={formData.state}
                            onChange={handleStateChange}
                            disabled={!formData.country || edit}
                            required
                        >
                            <option value="">Select State</option>
                            {stateList.map((state) => (
                                <option key={state.isoCode} value={state.isoCode}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                        <label className=" mb-1">
                            City <span className="text-danger">*</span>
                        </label>
                        <select
                            className="form-select"
                            name="city"
                            value={formData.city}
                            onChange={handleChangeUser}
                            disabled={!formData.state || edit}
                            required
                        >
                            <option value="">Select City</option>
                            {cityList.map((city) => (
                                <option key={city.name} value={city.name}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-3 mb-5">
                    {edit ? '' : <button className="btn btn-primary" type="submit" disabled={loading}>
                        {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                            "Submit"
                        )}
                    </button>}
                </div>
            </form>
        </div>
    );
}
