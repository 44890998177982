import React, { useEffect, useState } from 'react'
import { FaStar } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { GoOrganization } from "react-icons/go";
import { FaListCheck } from "react-icons/fa6";
import { GiHandBag } from "react-icons/gi";
import { GrUserExpert } from "react-icons/gr";
import { FaBusinessTime } from "react-icons/fa";
import { FaRegListAlt } from "react-icons/fa";
import { MdOutlineFactCheck } from "react-icons/md";
import { getProfileData } from '../../Services/AllApi';
import Loader from '../../Components/Loader/Loader';
import { IoCloseSharp } from "react-icons/io5";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';
import { baseUrl } from '../../Services/Endpoint';

const stripePromise = loadStripe('pk_live_51Q638VL06v98rXHkK6B7MpLNswfPfqw1iUUNsR53eFaj8ENLR1xUixMYmRtnjZN52FT8Wr0o91CQ2baVu5WA8dv000ZBEE3jzZ');

// const stripePromise = loadStripe(
//   "pk_test_51Q638VL06v98rXHkhl5qtB6ItFHwGhu0ryjlRknqn1DvjgdvJeGbpejc6SiEcWPxaRHpmjECKYjKwcBrTuDE2HDg001UcC2ufb"
// );

export default function PaymentPage() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})

    const [clientSecret, setClientSecret] = useState('');
    const [openModal, setOpenModal] = useState(false)

    const options = {
        clientSecret: clientSecret,
    };


    useEffect(() => {
        getPaymentIntent()
    }, []);


    async function getPaymentIntent() {
        try {
            let res = await axios.post(`${baseUrl}user/create-payment-intent`, { amount: 2500, type: 'aed' })
            let key = res?.data?.clientSecret
            setClientSecret(key)
            console.log(key)
        } catch (err) {
            console.log(err)
        }
    }




    async function getData() {
        setLoader(true)
        try {
            let res = await getProfileData(id)
            let allData = res?.data?.user || {}
            console.log(allData)
            setData(allData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <>
            <Loader open={loader} />
            <Modal isOpen={openModal} size='md' centered >
                <ModalBody>
                    <div className='d-flex justify-content-end mb-3'>
                        <IoCloseSharp size={18} className='pointer' onClick={() => setOpenModal(false)} />
                    </div>
                    {clientSecret && (
                        <Elements stripe={stripePromise} options={options}>
                            {/* <button className='btn btn-success'>Pay Now</button> */}
                            <CheckoutForm />
                        </Elements>
                    )}
                </ModalBody>
            </Modal>
            <div className='container py-4'>
                <h3 className='fw-600'>Subscribe to Eyemast<FaStar color='#007bff' />r</h3>
                <div className='mt-4'>

                    <div className='row'>
                        <div className='col-12 px-3' >
                            <div className='d-flex align-items-center gap-3 flex-md-row flex-sm-row flex-column'>
                                <div className='profilePreview'>
                                    {data?.profile?.fileUrl ?
                                        <img
                                            src={data?.profile?.fileUrl}
                                            alt="Profile Preview"
                                            style={{ width: 'auto', height: '100%' }}
                                        />
                                        : <img
                                            src='/images/profile.png'
                                            alt="Profile Preview"
                                            style={{ width: 'auto', height: '100%' }}
                                        />}
                                </div>
                                <div className='ml-md-4 ml-sm-4 ml-0 mt-md-0 mt-sm-0 mt-3'>
                                    <div className='w-100 d-flex justify-content-md-start justify-content-sm-start justify-content-center'>
                                        <h4 className='fw-600'>{data?.prefix} {data?.firstname}</h4>
                                    </div>
                                    <div className='d-flex  flex-wrap'>
                                        <div className='d-flex align-items-center mt-2 me-3'>
                                            <FiPhone className='f-17' /> <div className='ml-2 text-gray fw-500'>{data?.mobile}</div>
                                        </div>
                                        <div className='d-flex align-items-center mt-2'>
                                            <IoLocationOutline className='f-18' /> <div className='ml-2 text-gray fw-500'>{data?.city}, {data?.state}, {data?.country}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-100 border rounded mt-4'>
                        <div className='f-18 fw-600 ps-3 pt-3'>Profile Details :-</div>
                        <hr />
                        <div className='px-3 pb-3'>
                            <div className='row'>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <GoOrganization size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Eye Care Organization</div>
                                            <div className='f-17 fw-600'>{data?.company}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <FaListCheck size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Specialities</div>
                                            <div className='f-17 fw-600'>{data?.speciality?.join(', ')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <GiHandBag size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Profession</div>
                                            <div className='f-17 fw-600'>{data?.profession}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <GrUserExpert size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Qualification</div>
                                            <div className='f-17 fw-600'>{data?.qualification}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <FaBusinessTime size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Years of Experience</div>
                                            <div className='f-17 fw-600'>{data?.yearsOfExperience} Years</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <FaRegListAlt size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Per Day Eye Test Count</div>
                                            <div className='f-17 fw-600'>{data?.perDayEyeTest}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <MdOutlineFactCheck size={20} />
                                        <div className='ms-3'>
                                            <div className='text-gray f-14'>Slit Lamp Available</div>
                                            <div className='f-17 fw-600'>{data?.slitLampAvailable ? 'Yes' : 'No'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='w-100'>
                        <div className='f-18 fw-600 '>Payment Details :-</div>
                        <div className='mt-2'>You Have to pay subscribtion amount to visible to customers & Patients,</div>
                        <div className='d-flex align-items-center mt-3'>
                            <div className='fw-600 f-18'>Total Amount -</div>
                            <div className='d-flex ms-3'>
                                <div className='bg-primary text-white f-14 fw-600 px-2 d-flex align-items-center rounded'>{data?.country == 'India' ? 'INR' : 'AED'}</div>
                                <div className='fw-700 f-20 ms-2'>{data?.country == 'India' ? 100.00 : 25.00}</div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mt-2'>
                        <div className='text-gray f-14'>Total amount to be paid in {data?.country == 'India' ? 'INR' : 'AED'}</div>
                    </div>
                    <div className='mt-3'>
                        {data?.country == 'India' ? '' :<button className='btn btn-primary' onClick={() => setOpenModal(true)}>Pay Now</button>}
                        
                    </div>
                </div>
            </div>
        </>
    )
}






const CheckoutForm = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            return;
        }
        setLoading(true)

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `https://professional.eyemastr.com/success?id=${id}`, 
            },
        });

        if (result.error) {
            // Show error to your customer
            console.log(result.error.message);
        } else {
            // The payment is successful
            console.log('Payment successful!');
            // let paymentData = {
            //     paymentId: '',
            //     paymentStatus: '',
            //     paymentMethod: '',
            //     upiId: ''
            // }

            // const paymentIntent = result.paymentIntent;


            // const paymentId = paymentIntent.id; 
            // const paymentStatus = paymentIntent.status;
            // const paymentMethod = paymentIntent.payment_method; 
            // paymentData.paymentId = paymentId
            // paymentData.paymentStatus = paymentStatus
            // paymentData.paymentMethod = paymentMethod

            // if (paymentIntent.charges.data[0]?.payment_method_details?.upi) {
            //     const upiId = paymentIntent.charges.data[0].payment_method_details.upi.vpa; 
            //     paymentData.upiId = upiId
            // }
            // sessionStorage.setItem('paymentData',paymentData)
        }
    };

    const paymentElementOptions = {
        defaultValues: {
          billingDetails: {
            address: {
              country: "AE" // ISO code for UAE
            }
          }
        }
      };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement options={paymentElementOptions} />
            <button className='btn btn-primary mt-3 w-100' disabled={!stripe || loading}>Submit</button>
        </form>
    );
};


