import React, { useState } from "react";
import "./ShareYourConcern.css";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function ShareYourConcerns() {
  const [selectedConcerns, setSelectedConcerns] = useState([]);
  const navigation = useNavigate();

  const symptoms = [
    "Headache",
    "Blurred vision all time",
    "Tiredness during computer work",
    "Frequent watering in eyes",
    "Feeling dryness in eyes",
    "Frequent redness in eyes",
    "Blurred vision in near work",
    "Blurred vision in distance",
    "Squeezing eyes while watching TV",
    "Difficulty in Sunlight",
  ];

  const handleSymptomClick = (symptom) => {
    if (selectedConcerns.includes(symptom)) {
      setSelectedConcerns(selectedConcerns.filter((item) => item !== symptom));
    } else {
      setSelectedConcerns([...selectedConcerns, symptom]);
    }
  };

  const handleSubmit = () => {
    sessionStorage.setItem(
      "selectedConcerns",
      JSON.stringify(selectedConcerns)
    );
    navigation("/symptoms");
  };

  return (
    <div className="container">
      <div className="mt-md-4 mt-3 d-flex justify-content-between align-items-center">
        <div>
        <Link to="https://eyemastr.com/" className="link_style">
          <button
            className="btn border p-1 px-2"
          >
           <IoIosArrowBack size={18}/>
          </button>
        </Link>
        </div>
        <div className="fw-500 f-18">Share your Concerns</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div className="app-container mt-4">
        <p className="description">
          Tell us about your problem area by selecting the matching 3D model and
          choose the tabs correctly to help us accurately assess your symptoms.
        </p>
        <div className="model-display mt-4">
          <div className="model-placeholder">3D Model</div>
        </div>
        <h3 className="symptom-title">Let us know what you feel</h3>
        <div className="symptom-buttons">
          {symptoms.map((symptom, index) => (

            <button
              key={index}
              className={`symptom-button ${selectedConcerns.includes(symptom) ? "selected" : ""
                }`}
              onClick={() => {
                handleSymptomClick(symptom);
              }}

            >
              {symptom}
            </button>
          ))}
        </div>
        <div className="row justify-content-center pb-5">
          <div className="col-md-3">
            <button className="submit-button w-100 fw-500" onClick={handleSubmit} disabled={selectedConcerns.length === 0}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
