import React, { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { addOTP, professionalSignup, verifyOTP, userVerifyOtp } from '../../Services/AllApi';

const OTPVerify = () => {

    const [loading, setLoading] = useState(false);
    const [otpDigits, setOtpDigits] = useState(["", "", "", ""]);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const location = useLocation();
    const payload = location.state?.payload || {};
    const payloadUserdata = location.state?.payloadUser
    const userRoles = location.state?.checkRole


    const handleChange = (index, e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, "");
        const newOtpDigits = [...otpDigits];
        newOtpDigits[index] = value;
        setOtpDigits(newOtpDigits);
        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        const value = e.target.value;
        if (e.key === "Backspace" && !value && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };



    const handleOTP = async (e) => {
        e.preventDefault();
        const data = otpDigits?.join("");
        let payload2 = {
            mobile: payload?.mobile,
            otp: data,
        };
        let payload3 = {
            mobile: payloadUserdata?.mobile,
            otp: data,
        }
        try {
            setLoading(true)
            if (userRoles === true) {

                const response = await verifyOTP(payload2);
                if (response?.data?.success) {
                    toast.success("OTP has been Verified Successfully")
                    const response = await professionalSignup(payload);
                    const userInfo = {
                        token: response?.data?.token,
                    };
                    localStorage.setItem('UserDetail', JSON.stringify(userInfo));
                    toast.success("Your Professional Account has been Created!")
                    navigate("/professionalProfile")
                    setLoading(false)
                }
            } else {
                const responseUser = await userVerifyOtp(payload3)
            
                if (responseUser?.data.userData?.firstname) {
                    const userToken = {
                        token: responseUser?.data?.token,
                    };
                    localStorage.setItem("UserDetail", JSON.stringify(userToken));
                    // toast.success("login successfully");
                    // window.location.href = "https://eyemastr.com/"
                    navigate("/view-profile");
                } else {
                    navigate("/user-profile",{state:responseUser?.data?.token});
                }

            }
        } catch (error) {
            setLoading(false)
            const errorMessage = error?.response?.data?.message;
            toast.error(errorMessage);
        }

    }

    const handleResend = async () => {
        const payload2 = {
            mobile: payload?.mobile
        }
        try {
            // setLoading(true)
            const response = await addOTP(payload2);
            console.log(response)
            toast.success("OTP has been sent on your Mobile Number");
            // setLoading(false)
        } catch (error) {
            console.log(error);
            // setLoading(false);
            const errorMessage = error?.response?.data?.message;
            toast.error(errorMessage);
        }
    }



    return (
        <div className='registrationBanner p-md-5 p-3'>
            <div className='container py-5'>
                <div className='row py-5'>
                    <div className='col-lg-6 col-md-10 col-12 px-md-3 px-3 py-4 rounded bg-white'>
                        <div className="d-flex justify-content-center">
                            <span className="text-center heading">
                                Verify Your Mobile Number
                            </span>
                        </div>
                        <div className="d-flex justify-content-center">
                            <span className="mt-3 text-center heading">
                                Enter Your OTP
                            </span>
                        </div>
                        <div className="otp-input-container d-flex justify-content-center gap-2 mt-3">
                            {otpDigits.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    className="otp-input"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    maxLength={1}
                                    required
                                />
                            ))}
                        </div>
                        <div className="d-flex flex-column mt-5">
                            <button
                                className='btn btn-primary w-100'
                                type='button'
                                disabled={loading}
                                onClick={handleOTP}
                            >
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    " Verify OTP"
                                )}
                            </button>
                            {/* <span className="text-center mt-3 pointer" onClick={handleResend}>
                            Resend OTP
                        </span> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default OTPVerify;
